import React, { Component } from 'react';
import _ from 'lodash';
import sessionStore from '@markerspro/session-store';
export class CheckPermission {
    returnPageCurrentPermission = (menuCode) => {
        const permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false };
        if (!!permissionList && permissionList.length > 0) {
        if (permissionList.includes(menuCode + "_VIEW")) {
             currentPagePermissions.viewPermission = true;
        }
        if (permissionList.includes(menuCode + "_EDIT")) {
             currentPagePermissions.editPermission = true;
        }
         if (permissionList.includes(menuCode + "_DELETE")) {
             currentPagePermissions.deletePermission = true;
        }
        }
        return currentPagePermissions;
    }
}
export default CheckPermission;
