import ExecuteArgs from '../shared/model/executeArgs';
import * as actions from '../shared/store/actions/index';
// import commonConfig from '@markerspro/common-config';
import endPointConfig from './commonEndPointConfig';
import DataTable from '@markerspro/markers-datatable';
import MarkersDelete from './component/markersDeleteComponent';
import i18n from '@markerspro/i18n';
import MarkersTextarea from '../common/component/markersTextarea';
import sessionStore from '@markerspro/session-store';
import Validation from '@markerspro/validation-component';
import MarkersCommonSelect from '@markerspro/markers-common-select';
import CommonCommentBox from './component/commonCommentBox';
import ConvertDate from '@markerspro/markers-convert-date';
import ConvertTimeZone from '@markerspro/markers-convert-timezone';
import CommonFuncs from '@markerspro/common-function';
import MarkersDatePicker from '@markerspro/markers-datefield';
import MarkersDatePickerV2 from '@markerspro/markers-datepicker';
import * as actionsCommon from '../shared/store/actions/index';
import { usePrevious } from '../../Hooks/index';
import { redirectBuild } from './component/commonBuildRedirect';
import MarkersLink from '../common/component/markersLinkComponent';
import MarkersSelect from '../common/component/markersSelect';
import MarkersTextField from '../common/component/markersTextField';
import MarkersTimeField from '../common/component/markersTimeField';
import MarkersRadio from '../common/component/markersRadio';
import MarkersCheckbox from '../common/component/markersCheckbox';
import MarkersSwitchField from '../common/component/markersSwitchField';
import MarkersFileManager from '../common/component/filemanager/markersFileManagerComponent';
import MarkersFileUpload from '../common/component/markersFileUpload';
import CheckPermission from '../common/component/markersCheckPermissionComponent';
const packageCommonConfig = require('@markerspro/common-config').default
const localConfig = require('./localConfig').default
const commonConfig = { ...packageCommonConfig, ...localConfig }
export {
    actions,
    Validation,
    ExecuteArgs,
    commonConfig,
    i18n,
    sessionStore,
    DataTable,
    MarkersCommonSelect,
    ConvertDate,
    ConvertTimeZone,
    CommonFuncs,
    endPointConfig,
    MarkersDelete,
    MarkersDatePicker,
    actionsCommon,
    usePrevious,
    redirectBuild,
    CommonCommentBox,
    MarkersDatePickerV2,
    MarkersTimeField,
    MarkersLink,
    MarkersSelect,
    MarkersTextField,
    MarkersTextarea,
    MarkersRadio,
    MarkersCheckbox,
    MarkersSwitchField,
    MarkersFileManager,
    MarkersFileUpload,
    CheckPermission,
    localConfig
}
