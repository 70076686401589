import React from "react";
import Validation from '@markerspro/validation-component';
const MarkersSwitchField = React.memo((props) => {
    const { divClassName, onlyField, title, required, isError } = props;
    let attribute = { ...props.attribute }
    if (!attribute.hasOwnProperty('checked')) {
        attribute = { ...attribute, checked: attribute.value }
        delete attribute.value
    }
    if (attribute.hasOwnProperty('placeholder'))
        delete attribute.placeholder
    const fieldResult = <React.Fragment>
        <label className="d-flex align-items-center mr-4">
            <div className="fluid-switch-toggle cursor-pointer">
                <input className="switch-toggle-state" type="checkbox" {...attribute} />
                <div className="switch-indicator"></div>
                <div className="switch-activebg"></div>
            </div>
            <span className="switch-text">{title}</span>
        </label>
        {!!required && <Validation data={{
            field: title,
            errorClass: attribute.name + '_error',
            value: attribute.value || '',
            isError: isError,
            validationType: 'checkbox'
        }} returnValidation={this.functionValidation} />}
    </React.Fragment>
    return (
        !!onlyField ?
            fieldResult :
            <div className={!!divClassName ? divClassName : "form-group mt-2 mb-0"}>
                {fieldResult}
            </div>
    )
})
export default MarkersSwitchField;