import React from "react";
const MarkersRadio = React.memo((props) => {
    const { divClassName, onlyField, title } = props;
    let attribute = { ...props.attribute }
    if (!attribute.hasOwnProperty('checked')) {
        attribute = { ...attribute, checked: attribute.value }
        delete attribute.value
    }
    if (attribute.hasOwnProperty('placeholder'))
        delete attribute.placeholder
    const fieldResult = <React.Fragment>        
        <label className="fluid-radio d-flex align-items-center">
            <input type="radio" {...attribute} />
            <span className="label"></span>{title}
        </label>
    </React.Fragment>
    return (
        !!onlyField ?
            fieldResult :
            <div className={!!divClassName ? divClassName : "form-group mt-2 mb-0"}>
                {fieldResult}
            </div>
    )
})
export default MarkersRadio;