import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../shared/BaseComponent'
import ExecuteArgs from '../../shared/model/executeArgs';
import i18n from '@markerspro/i18n';
import Validation from '@markerspro/validation-component';
import commonConfig from '@markerspro/common-config';
import * as actionsCommon from '../../shared/store/actions/index';
class MarkersDelete extends BaseComponent {
    initialFields = {
        id: null,
        deletionReason: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            fields: { ...this.initialFields },
            requiredFields: [
                { fieldName: 'deletionReason', fieldType: 'default' }
            ],
            isError: 0,
            isDelete: false,
            showDeleteNotify: false,
            hideDeleteReason: false
        }
        if (props.useDeleteSuccessRef) this.setDeleteSuccessRef = React.createRef(props.setDeleteSuccess);
    }
    componentDidMount = () => {
        window.$(`${!!this.props.customModalIdName ? `#${this.props.customModalIdName}` : `#deleteModal`}`).modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });
        window.$(`${!!this.props.customModalIdName ? `#${this.props.customModalIdName}` : `#deleteModal`}`).on('hidden.bs.modal', () => {
            this.setState({ fields: this.initialFields, showDeleteNotify: false }, () => {
                this.props.onResetProps({ name: 'commonDelete', data: false });
                this.props.useDeleteSuccessRef ? this.setDeleteSuccessRef.current(null) : this.props.setDeleteSuccess(null);
            });
        });
    }
    handleChange = (event) => {
        let fields = Object.assign({}, this.state.fields);
        fields[event.target.name] = event.target.value.replace(/^\s+/g, '');    //trims space at start
        this.setState({ fields: fields });
    }
    componentWillReceiveProps(nextProps) {
        if (!!nextProps.setDeleteSuccess && this.props.useDeleteSuccessRef) {
            this.setDeleteSuccessRef.current = nextProps.setDeleteSuccess
        }
        if (!!nextProps.deleteId || (this.props.hasOwnProperty('isStatic') && !!nextProps.isStatic && nextProps.deleteId === 0)) {
            this.setState({ fields: { ...this.initialFields, id: nextProps.deleteId }, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$(`${!!this.props.customModalIdName ? `#${this.props.customModalIdName}` : `#deleteModal`}`).modal('show');
            });
        }
        if (!!nextProps.commonDelete && this.state.isDelete) {
            this.setState({ fields: this.initialFields, isDelete: false, showDeleteNotify: true, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$(`${!!this.props.customModalIdName ? `#${this.props.customModalIdName}` : `#deleteModal`}`).modal('hide');
                this.props.setDeleteSuccess(null, true);
            });
        }
    }
    handleShowDeleteReason = event => {
        event.preventDefault();
        this.setState({ hideDeleteReason: false })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        if (submitStatus === 0 && !(!!this.props.hideDeleteReason)) {
            this.setState({ isError: 1 });
        } else if (this.props.hasOwnProperty('isStatic') && !!this.props.isStatic) {
            this.setState({ isError: 0, isDelete: false }, () => {
                window.$(`${!!this.props.customModalIdName ? `#${this.props.customModalIdName}` : `#deleteModal`}`).modal('hide');
                this.props.setDeleteSuccess(null, true);
            });
        } else if (!!this.state.fields.id) {
            this.setState({ isError: 0, isDelete: true }, () => {
                let executedArgument = '';
                if (!!this.props.getParamFlag) {
                    executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint + this.state.fields.id + '&UnitId=' + this.props.param, commonConfig.METHOD_TYPE_POST);
                } else {
                    executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, this.state.fields);
                }
                this.props.onDeleteSuccess(executedArgument);
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div id={`${!!this.props.customModalIdName ? this.props.customModalIdName : `deleteModal`}`} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="deleteModalTitle" aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{i18n.t("areYouSureYouWantToDeleteTheRecord")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {(!this.state.hideDeleteReason && !(!!this.props.hideDeleteReason)) ?
                                <div className="modal-body">
                                    <div>
                                        <label className="col-form-label">{i18n.t("deletionReason")}<span>*</span></label>
                                        <textarea name="deletionReason" className="form-control" placeholder={i18n.t("enterDeletionReason")} value={this.state.fields.deletionReason || ''} style={{ height: "126px" }} onChange={(e) => this.handleChange(e)}></textarea>
                                    </div>
                                    <Validation data={{
                                        field: i18n.t("deletionReason"),
                                        errorClass: 'deletion_reason_error',
                                        value: this.state.fields.deletionReason,
                                        isError: this.state.isError,
                                        validationType: 'field'
                                    }} returnValidation={this.functionValidation} />
                                </div> : null
                            }
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close">{i18n.t('Cancel')}</button>
                                <button type="button" className="btn btn-danger" id="yesBtn" onClick={this.state.hideDeleteReason ? this.handleShowDeleteReason : this.handleSubmit}>{i18n.t('Delete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonDelete: state.commonStore.commonDelete
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onDeleteSuccess: (executeArgs) => dispatch(actionsCommon.callCommonDeleteApi(executeArgs, 'common')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersDelete)
