import React from "react";
import Validation from './validationComponent';
const MarkersTextField = React.memo((props) => {
    const { attribute, divClassName, onlyField, title, required, minLength, validationRegex, isError } = props;
    const fieldResult = <React.Fragment>
        <input type="text" {...attribute} />
        {/*{!!required ?*/}
        {/*    <Validation data={{*/}
        {/*        field: title,*/}
        {/*        errorClass: attribute.name + '_error',*/}
        {/*        value: attribute.value || '',*/}
        {/*        isError: isError,*/}
        {/*        minLength: !!minLength ? minLength : null,*/}
        {/*        validationType: 'field',*/}
        {/*        regExp: !!validationRegex ? new RegExp(validationRegex) : null*/}
        {/*    }} returnValidation={this.functionValidation} />*/}
        {/*    : !!validationRegex && <Validation data={{*/}
        {/*        field: title,*/}
        {/*        errorClass: attribute.name + '_error',*/}
        {/*        value: attribute.value || '',*/}
        {/*        isError: isError,*/}
        {/*        validationType: 'regExpNotRequired',*/}
        {/*        regExp: new RegExp(validationRegex)*/}
        {/*    }} returnValidation={this.functionValidation} />*/}
        {/*}*/}
    </React.Fragment>
    return (
        !!onlyField ?
            fieldResult :
            <div className={!!divClassName ? divClassName : "form-group"}>
                <label className="col-form-label">{title}{!!required && <span> *</span>}</label>
                {fieldResult}
            </div>
    )
})
export default MarkersTextField;