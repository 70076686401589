import React from 'react'
import { Link } from 'react-router-dom';
function MarkersLink(props) {
    let attribute = { ...props }
    if (!attribute.to || attribute.to === "")
        attribute.to = "#";
    if (attribute.to === "#")
        attribute.onClick = (event) => {
            event.preventDefault();
            if (!!props.onClick) {
                props.onClick();
            }
        }
    delete attribute.children;
    return <Link {...attribute}>{props.children}</Link>
}
export default MarkersLink;