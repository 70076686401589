import { SharedApiConfig } from '../sharedApiConfig';
import sessionStore from '@markerspro/session-store';
import { commonConfig } from '../../common/commonConfig'
const axios = require('axios');
const setLoggedInUserType = 'SET_LOGGEDIN_USER';
const getUserTokenType = 'GET_USERTOKEN';
const initialState = {
    loggedInUser: {}, userToken: '', isLoading: false
};
export const sharedActionCreators = {
    setLoggedInUser: loggedInUser => async (dispatch, getState) => {
        dispatch({ type: setLoggedInUserType, loggedInUser });
    },
    getUserToken: input => async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        })
            .then(function (response) {
                const userToken = response.data.token;
                dispatch({ type: getUserTokenType, userToken });
            }).catch(error => console.log(error));
    },
    refreshToken: input => async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        })
            .then(function (response) {
                const userToken = response.data;
            }).catch(error => console.log(error));
    },
};
export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === setLoggedInUserType) {
        return {
            ...state,
            loggedInUser: action.loggedInUser,
            isLoading: false
        };
    }
    if (action.type === getUserTokenType) {
        new sessionStore().storeSecureData(commonConfig.SESSION_TOKEN, action.userToken);
        return {
            ...state,
            userToken: action.userToken,
            isLoading: false
        };
    }
    return state;
};