import React, { Component } from 'react';
import { connect } from 'react-redux';
import sessionStore from '@markerspro/session-store';
import commonConfig from '@markerspro/common-config';
import MarkersNotify from '@markerspro/markers-notify';
import Swal from 'sweetalert2';
import * as actionTypesShared from '../components/shared/store/actions/actionTypesShared';
import * as actionsCommon from '../components/shared/store/actions/index';
const axios = require('axios');
var loadCount = 0;
class Intercepter extends Component {
    state = {
        isShowNotify: false,
        messageType: true,
        responseMessage: ''
    }
    componentWillMount() {
        axios.interceptors.request.use(function (config) {
            // Do something before request is sent
            loadCount++;
            var userId = sessionStore.ACTIVE_USER_ID;
            //Show loader
            window.$(".general-loader").show();
            window.$(".general-loader span p.switchText").html();
            if (userId) {
                config.headers['ActiveUserId'] = userId
            }
            var token = new sessionStore().returnSessionToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function (error) {
            // Do something with request error
            window.$(".general-loader").hide(); 
            return Promise.reject(error);
        });
        axios.interceptors.response.use(response => {
            loadCount--;
            if (loadCount === 0) {
                //Hide loader
                window.$(".general-loader").hide();
                if (new sessionStore().returnMicroService() && new sessionStore().returnMicroService() === commonConfig.IS_TRANSPORT)
                    new sessionStore().storeSecureData('markersService', '');
            }
            if (!!response.data.showMessage && !!response.data.message) {
                if (new sessionStore().returnNotificationStatus() && new sessionStore().returnNotificationStatus() === commonConfig.IS_HIDE)
                    new sessionStore().storeSecureData('markersNotificationStatus', '');
                else {
                    this.props.onShowNotify(response.data.message, !!response.data.success ? "success" : "error")
                }
            }
            //window.$(".general-loader").hide();
            return response;
        }, error => {
            window.$(".general-loader").hide();
            if (401 === error.response.status) {
                Swal.fire({
                    title: "Session Expired",
                    text: "Your session has expired. Would you like to be redirected to the login page?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel"
                }).then(() => {
                    new sessionStore().cleanSessionStore()
                    window.location = '/login';
                })
            } else {
                return Promise.reject(error);
            }
            return error;
        });
    }
    setShowNotifyStatus = (status) => {
        this.setState({ isShowNotify: status })
    }
    render() {
        const { notifyMessage, notifyType, isActiveComposeEmailPopup, isActiveComposeMessagePopup } = this.props.commonProps;
        if (new sessionStore().returnMicroService() && new sessionStore().returnMicroService() === commonConfig.IS_TRANSPORT) {
            return (
                <React.Fragment></React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    {!!notifyMessage &&
                        <MarkersNotify
                            isShowNotify={this.props.onRemoveNotify}
                            message={notifyMessage}
                            type={!!notifyType ? notifyType : "success"}
                        />}

                </React.Fragment>
            )
        }
    }
}
const mapStateToProps = state => {
    return {
        commonProps: state.commonStore
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onRemoveNotify: () => dispatch({ type: actionTypesShared.REMOVE_NOTIFY })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Intercepter);