import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import i18n from '@markerspro/i18n';
import sessionStore from '@markerspro/session-store';
import commonConfig from '../../common/commonConfig';
import CommonFuncs from '@markerspro/common-function'
import { redirectBuild } from "../../common/commonLmsIndex";
import CryptoAES from 'crypto-js/aes';
import { hostArray } from '../../../hostConfig';
//import CryptoENC from 'crypto-js/enc-utf8';
const commonFuncObj = new CommonFuncs();
var SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
const lmsUrl = ['/assessment/assignmentBank', '/assessment/assignmentItem', '/assessment/assignmentItemsChild', '/assessment/assignmentCategory', '/assessment/assignmentDetails'];
class MarkersMenuAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relativeUrl: '',
            redirectStatus: false,
            billingTransportLmsServNames: [commonConfig.IS_BILLING, commonConfig.IS_TRANSPORT, commonConfig.IS_LMS]
        }
    }
    //Nav Active inactive.
    getNavLinkClass = (pathArr, path, exact = true) => {
        var isActive = pathArr.includes(path);
        return isActive === true ? 'active' : '';
    }

    collapseClass = (pathArr, path) => {
        if (!Array.isArray(pathArr)) {
            pathArr = pathArr.split(",");
        }
        var collapse = pathArr.includes(path);
        return collapse === true ? 'collapse show' : 'collapse';
    }

    activeLinkClass = (activeRoute, currentUrl) => {
        return activeRoute === currentUrl ? 'active' : '';
    }
    returnSessionItems() {
        //CryptoAES.decrypt(schoolId.toString(), SECRET_KEY).toString(CryptoENC);
        return 'token=' + new sessionStore().returnSessionToken() + '&l=' + new sessionStore().returnLanguage() + '&sc=' + CryptoAES.encrypt(new sessionStore().returnSchoolID().toString(), SECRET_KEY) +
            '&se=' + CryptoAES.encrypt(new sessionStore().returnSessionID().toString(), SECRET_KEY) + '&d=' + CryptoAES.encrypt(new sessionStore().returnDistrictID().toString(), SECRET_KEY) +
            '&sy=' + CryptoAES.encrypt(new sessionStore().returnSchoolYear().toString(), SECRET_KEY) + '&m=' + CryptoAES.encrypt(new sessionStore().returnMarkingperiodId().toString(), SECRET_KEY) + '&r=' + CryptoAES.encrypt(new sessionStore().returnUserRole().toString(), SECRET_KEY) +
            '&st=' + CryptoAES.encrypt(new sessionStore().returnStaffID().toString(), SECRET_KEY) + '&u=' + CryptoAES.encrypt(new sessionStore().returnUserID().toString(), SECRET_KEY) +
            '&mc=' + CryptoAES.encrypt(new sessionStore().returnCurrentMenuCode(), SECRET_KEY) +
            '&un=' + CryptoAES.encrypt(new sessionStore().returnUserName(), SECRET_KEY) +
            '&ri=' + CryptoAES.encrypt(new sessionStore().returnUserRoleID().toString(), SECRET_KEY) +
            '&ev=' + CryptoAES.encrypt(new sessionStore().returnHostEnvironment().toString(), SECRET_KEY) +
            '&stu=' + CryptoAES.encrypt(new sessionStore().returnStudentID().toString(), SECRET_KEY) +
            '&lue=' + CryptoAES.encrypt(new sessionStore().returnLoggedUserEmail(), SECRET_KEY)
    }

    handleMenuClick = (e, relativeUrl, menuCode) => {
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false, approvalPermission: false, verifyPermission: false, certifyPermission: false, publicationPermission: false };
        let checkCurrentUserRoleBySchool = _.find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        if (!!menuCode && !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') {
            if (menuCode === 'STUD_GENERAL') {
                menuCode = 'STUD_GEN_INFO';
            }
            if (permissionList && permissionList.length > 0) {
                if (permissionList.includes(menuCode + "_VIEW")) {
                    currentPagePermissions.viewPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT")) {
                    currentPagePermissions.editPermission = true;
                }
                if (permissionList.includes(menuCode + "_DELETE")) {
                    currentPagePermissions.deletePermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_APPR")) {
                    currentPagePermissions.approvalPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_VERY")) {
                    currentPagePermissions.verifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_CERT")) {
                    currentPagePermissions.certifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_PUBL")) {
                    currentPagePermissions.publicationPermission = true;
                }
            }
        } else {
            currentPagePermissions.viewPermission = true;
            currentPagePermissions.editPermission = true;
            currentPagePermissions.deletePermission = true;
            currentPagePermissions.approvalPermission = true;    
            currentPagePermissions.verifyPermission = true;
            currentPagePermissions.certifyPermission = true;
            currentPagePermissions.publicationPermission = true;
        }  
        new sessionStore().removeSessionItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MENU_CODE);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MANAGE_INCIDENT_TYPE);
        new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
        new sessionStore().storeSecureData(commonConfig.SESSION_CURRENT_PAGE_PERMISSION, JSON.stringify(currentPagePermissions));
        if (menuCode === 'DASH_MAIN') {
            relativeUrl = '/dashboard';
        }
        if (lmsUrl.includes(relativeUrl) === true)
            relativeUrl = '/lms'.concat(relativeUrl);
        this.setState({ relativeUrl: relativeUrl, redirectStatus: true }, () => {
            if (relativeUrl.includes(commonConfig.IS_LMS) === false && lmsUrl.includes(relativeUrl) === false) {
                let { billingTransportLmsServNames } = this.state;
                let microService = relativeUrl.split("/")[1];
                let isHomeBuild = billingTransportLmsServNames.indexOf(microService) === -1;
                redirectBuild(relativeUrl, isHomeBuild ? commonConfig.IS_HOME : microService, menuCode);
            }
        });
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.navbarRedirect && nextState.redirectStatus) {
            this.setState({ redirectStatus: false, relativeUrl: '' });
        }
    }
    render() {
        if (this.state.redirectStatus && !!this.state.relativeUrl) {
            return <Redirect to={this.state.relativeUrl} />
        }
        return (
            <React.Fragment>
                {
                    //LMS related changes...
                    this.props.navBarList && this.props.navBarList.length > 0 && this.props.navBarList.sort(commonFuncObj.compareValues('sortOrder')) && this.props.navBarList.map((menuValue, index) =>
                        // menuCodes.map((menuValue, index) =>
                        menuValue.child.length === 0 ?
                            !!menuValue.menuCode && menuValue.isActive === true ?
                                <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, menuValue.relativeUrl, menuValue.menuCode)} className={this.getNavLinkClass(menuValue.activeRoutes, window.location.pathname)}><i className={menuValue.menuClass + " mr-2"}></i> {i18n.t(menuValue.menuCode)}</Link></li >
                                :
                                null
                            :
                            !!menuValue.menuCode && menuValue.isActive === true ?
                                <li key={index} id={'heading' + menuValue.moduleName}><Link data-toggle="collapse" data-target={'#collapse' + menuValue.moduleName} aria-labelledby={'heading' + menuValue.moduleName} to="#" className={this.getNavLinkClass(menuValue.activeRoutes, window.location.pathname)}><i className={menuValue.menuClass + " mr-2"}></i> {i18n.t(menuValue.menuCode)}</Link>
                                    <ul id={'collapse' + menuValue.moduleName} className={this.collapseClass(menuValue.activeRoutes, window.location.pathname)} aria-labelledby={'heading' + menuValue.moduleName} data-parent="#accordionSidenav">
                                        {menuValue.child && menuValue.child.length > 0 && menuValue.child.sort(commonFuncObj.compareValues('sortOrder')) && menuValue.child.map((subMenuValue, index) =>
                                            subMenuValue.isActive === true && subMenuValue.isSidebar === 'False' ?
                                                subMenuValue.child && subMenuValue.child.length > 0 && subMenuValue.child.sort(commonFuncObj.compareValues('sortOrder')) ?
                                                    <li key={index}>
                                                        <Link to="#" data-toggle="collapse" className={this.collapseClass(subMenuValue.activeRoutes, window.location.pathname)} data-target={'#collapse' + subMenuValue.title} aria-labelledby={'heading' + subMenuValue.title}>{i18n.t(subMenuValue.menuCode)}</Link>
                                                        <ul id={'collapse' + subMenuValue.title} className="collapse" aria-labelledby={'heading' + subMenuValue.title}
                                                            data-parent={'#heading' + menuValue.moduleName}>
                                                            {subMenuValue.child.map((subChildMenuValue, index) =>
                                                                <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subChildMenuValue.relativeUrl, subChildMenuValue.menuCode)} className={this.activeLinkClass(subChildMenuValue.activeRoutes, window.location.pathname)}>{i18n.t(subChildMenuValue.menuCode)}</Link></li>
                                                            )}
                                                        </ul>
                                                    </li>
                                                    : <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subMenuValue.relativeUrl, subMenuValue.menuCode)} className={this.activeLinkClass(subMenuValue.activeRoutes, window.location.pathname)}>{i18n.t(subMenuValue.menuCode)}</Link></li>
                                                :
                                                null
                                        )
                                        }
                                    </ul>
                                </li>
                                :
                                null
                    )}
            </React.Fragment>
        )
    }
    //Return sidebar items dynamically.
    //returnSidebarItems(path) {
    //    var sidebarDetails = sidebarList.map((sidebarValue) =>
    //        sidebarValue.isActive === true ? <li data-position={sidebarValue.sortOrder}><Link to={{ pathname: sidebarValue.menuLink }} className={this.getNavLinkClass(sidebarValue.activeRoutes, path)}>{i18n.t(sidebarValue.menuName)}</Link></li> : null
    //    );

    //    return sidebarDetails;
    //}

}
export default MarkersMenuAccess;

