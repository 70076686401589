import i18n from '@markerspro/i18n';

export class MarkersMultiLingual {

    returnLanguage(param)
    {
        return i18n.t(param);
    }
}

export default MarkersMultiLingual;
