import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { i18n, MarkersLink, MarkersDelete, Validation, CommonFuncs, endPointConfig, commonConfig, actions, ExecuteArgs, sessionStore, ConvertDate, usePrevious } from '../commonLmsIndex';

let moment = require('moment'); 
function CommonCommentBox(props) { 
    const commonFuncObj = new CommonFuncs();
    let scrollDiv = useRef();
    const initialFields = {
        studentId: 0,
        studentPersonId: 0,
        teacherId: "",
        comment: "",
        activityId: 0,
        selectedFileList: []
    }
    const [state, setState] = useState({
        fields: { ...initialFields },
        label: null,
        deleteId: null
    });
    const prevIsopenModal = usePrevious(props.isOpenModal);
    let { handleForm, teacherId, studentId, activityId, studentPersonId, isTeacherScreen, isQuizAssignment, currentPagePermissions } = props;
    let activityDiscussionList;
    useEffect(() => {
        window.$('#studentCommnentsModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });

        window.$("#deleteCommentBoxModal").on('hidden.bs.modal', () => {
            window.$("#studentCommnentsModal").modal("show");
        });
        getAllActivityDiscussionComments(activityId);
        props.onResetProps({ name: 'activityDiscussionList', data: [] });
        return () => {
            props.onResetProps({ name: 'activityDiscussionList', data: [] });
        }
    }, []);
    useEffect(() => {
        if (prevIsopenModal === false && !!props.isOpenModal) {
            getAllActivityDiscussionComments(activityId);
        }
        let { fields } = state;
        fields['comment'] = '';
        setState({ ...state, fields });
        window.$("#studentCommnentsModal").modal(!!props.isOpenModal ? "show" : "hide");
    }, [props]);

    useEffect(() => {
        if (props.activityDiscussionList && props.activityDiscussionList.length > 0) {
            !!scrollDiv.current && scrollDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [props.activityDiscussionList]);

    useEffect(() => {
        if (props.saveActivityDiscussion && !!props.saveActivityDiscussion.id) {
            props.onResetProps({ name: 'saveActivityDiscussion', data: {} });
            getAllActivityDiscussionComments(activityId);
            if (props.hasOwnProperty('assignmentSubmissionId') && !!props.assignmentSubmissionId) {
                let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                    endPointConfig.COMPONENT_GET_ASSIGNMENT_SUBMISSION_DETAILS_BYID + props.assignmentSubmissionId,
                    commonConfig.METHOD_TYPE_GET);
                props.onGetAllStudents(executedArgument, 'student submission details');
            }
            setState({ ...state, fields: { ...initialFields }, deleteId: null });
        }
    }, [props.saveActivityDiscussion]);

    const getAllActivityDiscussionComments = (id) => {
        if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_PARENT && props.getAssignment && props.getAssignment.discussionForumId) {
            const executedArgument2 = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                endPointConfig.COMPONENT_DISCUSSION_FORUM_SUBMISSION_TEACHER_COMMENT_GETALL + parseInt(props.getAssignment.discussionForumId, 10),
                commonConfig.METHOD_TYPE_GET);
            props.onGetDiscussionSucess(executedArgument2);
        }
        else {
            let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                endPointConfig.COMPONENT_LMS_ACTIVITY_DISCUSSION_GET_ALL + id,
                commonConfig.METHOD_TYPE_GET);
            props.onGetDiscussionSucess(executedArgument);
        }
    }

    useEffect(() => {
        if (!!props.getFileUrl && Object.keys(props.getFileUrl).length > 0) {
            commonFuncObj.download(props.getFileUrl.fileName, props.getFileUrl.contentType, props.getFileUrl.fileContent);
            props.onResetProps({ name: 'getFileUrl', data: {} });
        }
    }, [JSON.stringify(props.getFileUrl)])


    const downloadFile = (event, fileId) => {
        event.preventDefault();
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_DOWNLOAD_FILE + fileId, commonConfig.METHOD_TYPE_GET);
        props.onGetFile(executedArgument);
    }
    const saveComment = (e) => {
        e.preventDefault();
        let { fields } = state;
        if (!(!!fields.comment) && fields.selectedFileList.length === 0) {
            setState({ ...state, isError: 1 });
        } else {
            setState({ ...state, isError: 0, isInsertUpdate: true });//activityDiscussionList
            fields = { ...fields, activityId, studentId, teacherId, studentPersonId, isTeacher: isTeacherScreen }

            const fileData = new FormData();
            fileData.append('inputData', JSON.stringify(fields));
            fileData.append('component', endPointConfig.COMPONENT_LMS);
            fileData.append('endpoint', endPointConfig.COMPONENT_LMS_ACTIVITY_DISCUSSION_SAVE);
            if (fields.selectedFileList) {
                for (let file of fields.selectedFileList) {
                    fileData.append("File", file);
                }
            }
            props.onSaveSuccess(fileData);
        }
    }
    const handleChange = (event) => {//handleChangeFile
        let { fields } = state;
        fields[event.target.name] = event.target.value;
        setState({ ...state, fields });
    }

    const handleImageChange = (e) => {
        e.preventDefault();
        let { fields } = state;
        setState({ ...state, label: null, fields: { ...fields, selectedFileList: e.target.files } });
    }
    const handleDelete = (deleteId, success = false, e) => {
        !!e && e.preventDefault();
        setState({ ...state, deleteId });
        window.$("#studentCommnentsModal").modal("hide");
        success && getAllActivityDiscussionComments(activityId);
    }
    const fileType = (fileName) => {
        let currentFileType = !!fileName ? fileName.split(".").pop().toLowerCase() : '';
        let className;
        switch (currentFileType) {
            case 'pdf':
                className = 'icon-file-pdf';
                break;
            case 'xls':
            case 'xlsx':
                className = 'icon-file-excel';
                break;
            case 'doc':
            case 'docx':
                className = 'icon-file-word';
                break;
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'gif':
            case 'png':
                className = 'icon-image2';
                break;
            case 'zip':
            case 'rar':
                className = 'icon-file-zip';
                break;
            default:
                className = 'icon-folder4';
                break;
        }
        return className;
    }
    const isRenderCheckMark = (index) => {
        let array = activityDiscussionList.slice(index);

        return _.some(array, ['chatType', 'incoming']);
    }
    const renderFileUploadCommentBox = (data, index) => {
        let textColor = data.chatType === 'outgoing' ? `white` : `dark`;
        return (
            <div className={data.chatType} ref={activityDiscussionList.length - 1 === index ? scrollDiv : null}>
                <div className="msg_bx">
                    <div className="msg_cmnt_bx">
                        <div className="media align-items-center">
                            <Link to="#" onClick={(e) => downloadFile(e, data.fileId)}>
                                <i className={`icon ${fileType(data.fileName)} text-${textColor} mr-2 f-s-35`}></i>
                            </Link>
                            <div className="media-body">
                                <Link to="#" onClick={(e) => downloadFile(e, data.fileId)} className={`mb-0 text-${textColor}`}>{data.fileName}</Link>
                                <p className="m-0"><small className={`text-${textColor}`}>{data.fileSize}</small></p>
                            </div>
                        </div>
                        <h6>{data.comment}</h6>
                    </div>
                    {data.chatType === 'outgoing' && (!!isTeacherScreen || (!isTeacherScreen && activityDiscussionList.length - 1 === index)) && !!currentPagePermissions.deletePermission &&
                        <div className="msg_icn_bx">
                            <Link to="#" className={`text-${textColor} d-block mb-2`} onClick={(e) => handleDelete(data.id, false, e)}><i className="icon icon-bin2"></i></Link>
                        </div>}
                </div>
                <div className="msg_bx">
                    <div className="msg_cmnt_bx">
                        <p className="m-0">{moment(data.commentTimestamp).format("MMM D, h:mm a")}</p>
                    </div>
                    {data.chatType === 'outgoing' && !!isRenderCheckMark(index) &&
                        <div className="msg_icn_bx">
                            <div className="text-blue-800 d-block"><i className="icon icon-checkmark2"></i></div>
                        </div>}
                </div>
            </div>
        )
    }

    const renderCommentBox = (data, index) => {
        let textColor = data.chatType === 'outgoing' ? `white` : `dark`;
        if (!!data.fileId) {
            return renderFileUploadCommentBox(data, index);
        } else {
            return (
                <div className={data.chatType} ref={activityDiscussionList.length - 1 === index ? scrollDiv : null} >
                    <div className="msg_bx">
                        <div className="msg_cmnt_bx">
                            <h6>{data.comment}</h6>
                        </div>
                        {data.chatType === 'outgoing' && (!!isTeacherScreen || (!isTeacherScreen && activityDiscussionList.length - 1 === index)) && !!currentPagePermissions.deletePermission &&
                            <div className="msg_icn_bx">
                                <Link to="#" className={`text-${textColor} d-block mb-2`} onClick={(e) => handleDelete(data.id, false, e)}><i className="icon icon-bin2"></i></Link>
                            </div>
                        }
                    </div>
                    <div className="msg_bx">
                        <div className="msg_cmnt_bx">
                            <p className="m-0">{moment(data.commentTimestamp).format("MMM D, h:mm a")}</p>
                        </div>
                        {data.chatType === 'outgoing' && !!isRenderCheckMark(index) &&
                            <div className="msg_icn_bx">
                                <div className="text-blue-800 d-block"><i className="icon icon-checkmark2"></i></div>
                            </div>}
                    </div>
                </div>
            )
        }
    }
    const closeModal = () => {
        handleForm(false);
        getAllActivityDiscussionComments(activityId);
    }
    let removeFile = (e) => {
        e.preventDefault();
        let { fields } = state;
        setState({ ...state, label: null, fields: { ...fields, selectedFileList: [] } });
    };
    activityDiscussionList = props.activityDiscussionList && props.activityDiscussionList.length > 0 ? _.filter(props.activityDiscussionList, activityDiscussion => activityDiscussion.studentId === studentId).sort((a, b) => new Date(a.commentTimestamp).getTime() - new Date(b.commentTimestamp).getTime()) : [];
    activityDiscussionList = activityDiscussionList.length > 0 && _.map(activityDiscussionList, data => ({ ...data, chatType: `${!!isTeacherScreen && !!data.isTeacher ? `outgoing` : !isTeacherScreen && !data.isTeacher ? `outgoing` : `incoming`}` }))
    let iconAttachment = <i className="icon icon-attachment" onClick={(e) => e.preventDefault()}></i>;
    return (
        <React.Fragment>
            <form action="">
                <div className="modal fade" id="studentCommnentsModal" >
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header pb-3">
                                {new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_STUDENT && <h5 className="modal-title">{i18n.t("teacherCommnents")}</h5>}
                                {new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_TEACHER && <h5 className="modal-title">{i18n.t("studentComments")}</h5>}
                                <button type="button" className="close" onClick={() => closeModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body commentChatBx" >
                                {Array.isArray(activityDiscussionList) && activityDiscussionList.length > 0 && activityDiscussionList.map((data, index) => {
                                    return (
                                        <React.Fragment>
                                            {renderCommentBox(data, index)}
                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>
                            <div className="modal-footer pt-3">
                                {state.fields.selectedFileList && state.fields.selectedFileList.length > 0 &&
                                    <div className="panel panel-default bg-light w-100 mb-0">
                                        <div className="panel-body d-flex align-items-center justify-content-between p-10">
                                            <p className="m-0">{state.fields.selectedFileList[0].name.split('.')}</p>
                                            <Link to="#" onClick={removeFile}><i className="icon icon-cross text-danger"></i></Link>
                                        </div>
                                    </div>
                                }
                                <div className="input-group commentBx">
                                    <div className="typeComment">
                                        <input type="text" autoComplete="off" value={state.fields.comment} name="comment" onChange={handleChange} className="form-control" placeholder="Enter Comment" />
                                        <div class="custom-file">
                                            <input type="file" className="custom-file-input" multiple={false} onChange={handleImageChange} />
                                            <label className="custom-file-label" onClick={(e) => e.preventDefault()}>{iconAttachment}</label>
                                        </div>
                                    </div>
                                    {!!currentPagePermissions.editPermission &&
                                        <div className="input-group-append">
                                            <button className="input-group-text" onClick={saveComment}><i className="icon-paperplane text-primary"></i></button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <MarkersDelete
                deleteId={state.deleteId}
                componentName={endPointConfig.COMPONENT_LMS}
                endPoint={endPointConfig.COMPONENT_LMS_ACTIVITY_DISCUSSION_DELETE}
                setDeleteSuccess={handleDelete}
                hideDeleteReason={true}
                customModalIdName='deleteCommentBoxModal'
            />
        </ React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        getAssignmentSubmissionDetails: state.commonStore.getAssignmentSubmissionDetails,
        saveActivityDiscussion: state.commonStore.saveActivityDiscussion,
        activityDiscussionList: state.commonStore.activityDiscussionList,
        getFileUrl: state.commonStore.getFileUrl,
        getAssignment: state.commonStore.getAssignment
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onGetAllStudents: (executeArgs, title) => dispatch(actions.callCommonGetByApi(executeArgs, title)),
        onResetProps: (executeArgs) => dispatch(actions.commonStoreStateReset(executeArgs)),
        onSaveSuccess: (executeArgs) => dispatch(actions.callCommonSaveApi(executeArgs, 'Activity Discussion', true)),
        onGetDiscussionSucess: (executeArgs) => dispatch(actions.callCommonListApi(executeArgs, 'Activity Discussion')),
        onGetFile: (executeArgs) => dispatch(actions.callCommonGetByApi(executeArgs, 'file url')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonCommentBox);
