import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Intercepter from '../security/intercepter';
import Layout from '../components/Layout';
import LoginComponent from '../components/common/pages/loginComponent';
import SsoComponent from '../components/common/pages/ssoComponent';
import styleComponent from './../components/common/styles/styleComponent';
import { translate, Trans } from 'react-i18next';
import commonConfig from '@markerspro/common-config';
import sessionStore from '@markerspro/session-store';
import DashBoardLayoutComponent from '../components/common/pages/dashboardLayoutComponent';
import ClearCache from "react-clear-cache";
import IdleTimer from 'react-idle-timer';
import { hostArray } from '../hostConfig';
import i18n from '@markerspro/i18n';
import CryptoENC from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
const SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
class App extends React.Component {
    constructor(props) {
        super(props)
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
        /* fetch data from URL */
        const indexArray = this.fetchDataFromUrl()
        if (indexArray.length !== 0) {
            const splitUrl = window.location.href.split("?")
            if (splitUrl.length !== 1) {
                indexArray.map(session => {
                    new sessionStore().storeSecureData(session.index, session.value)
                })
                this.props.history.replace(this.props.location.pathname)
            }
        }
    }
    fetchDataFromUrl = () => {
        const sessionIndex = {
            token: commonConfig.SESSION_TOKEN,
            sc: commonConfig.SESSION_SCHOOLID,
            se: commonConfig.SESSION_ID,
            d: commonConfig.SESSION_DISTRICTID,
            sy: commonConfig.SESSION_SCHOOLYEAR,
            m: commonConfig.SESSION_MARKING_PERIOD_ID,
            r: commonConfig.SESSION_USER_ROLE,
            st: commonConfig.SESSION_STAFFID,
            u: commonConfig.SESSION_USER_ID,
            l: commonConfig.SESSION_LANGUAGE,
            mc: commonConfig.SESSION_MENU_CODE,
            un: commonConfig.SESSION_USER_NAME,
            ri: commonConfig.SESSION_LOGGED_USER_ROLE,
            ev: commonConfig.SESSION_ENVIRONMENT,
            stu: commonConfig.SESSION_STUDENTID,
            ms: commonConfig.SESSION_MICRO_SERVICE,
            sed: commonConfig.CURRENT_SCHOOL_SESSION_END_DATE,
            sdf: commonConfig.SESSION_DATE_FORMAT,
            lue: commonConfig.SESSION_LOGGED_USER_EMAIL
        }
        const remoteUrl = window.location.href;
        let returnArray = []
        if (remoteUrl.indexOf('?') !== -1 && remoteUrl.lastIndexOf('?') === remoteUrl.indexOf('?')) {
            const splitUrl = remoteUrl.split("?")[1]
            const urlIndexList = splitUrl.split("&")
            for (let i = 0; i < urlIndexList.length; i++) {
                const indexObject = urlIndexList[i].split('=')
                if (!!sessionIndex[indexObject[0]]) {
                    const indexValue = `${indexObject[1]}${indexObject.length === 3 ? '=' : ''}`
                    returnArray.push({ 'index': sessionIndex[indexObject[0]], 'value': indexObject[0] === 'token' ? indexObject[1] : CryptoAES.decrypt(indexValue.toString(), SECRET_KEY).toString(CryptoENC) })
                }
            }
        }
        return returnArray
    }
    _onIdle() {
        if (new sessionStore().returnSessionToken() !== null) {
            var environMent = new sessionStore().returnHostEnvironment();
            var n = sessionStorage.length;
            while (n--) {
                var key = sessionStorage.key(n);
                sessionStorage.removeItem(key);
            }
            window.location.href = hostArray[environMent].home + '/sso';
        }
    }
    render() {
        return (
            <div className="App">
                {new sessionStore().returnSessionToken() !== null ?
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onIdle={this.onIdle}
                        debounce={250}
                        timeout={1000 * 60 * 15}
                    // timeout={1000 * 60 * parseInt(new sessionStore().returnIdleTimer(commonConfig.SESSION_IDLETIMER), 10)}
                    /> : null
                }
                <ClearCache auto={true}>
                    {({ isLatestVersion, emptyCacheStorage }) => (
                        <div>
                            {!isLatestVersion && emptyCacheStorage()}
                        </div>
                    )}
                </ClearCache>
                <Intercepter />
                <Layout>
                    <Route path="/" exact component={LoginComponent} />
                    <Route path="/login" component={LoginComponent} />
                    <Route path="/sso" component={SsoComponent} />
                    <Route path="/lms" render={
                        (props) => {
                            if (new sessionStore().returnSessionToken() !== null) {
                                document.title = `Markerspro - LMS`
                                return <DashBoardLayoutComponent {...props} />
                            } else {
                                return <Redirect to="/sso" />
                            }
                        }
                    } />
                </Layout>
            </div>
        );
    }
}
export default withRouter(translate('common')(App));