import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sharedActionCreators } from '../components/shared/store/sharedAction';
import asyncComponent from './asyncComponent';
import sessionStore from '@markerspro/session-store';
import { hostArray } from '../hostConfig';

const routeList = [
    'dashboard',
    'courseShell',
    'courseStructure/students',
    'courseStructure/associatedStaff',
    'courseStructure/studentList',
    'courseStructure/studentAssignment',
    'parentLmsFlow/parentCommunicationAnnouncement',
    'parentLmsFlow/courseStructureParentFileSubmission',
    'parentLmsFlow/courseStructureParentOnlineText',
    'parentLmsFlow/courseStructureParentUrl',
    'quizAssignment/teacher',
    'parentLmsFlow/courseStructureParentDiscussionForum',
    'quizAssignment/assignmentBank',
    'quizAssignment/assignmentGroup',
    'quizAssignment/onlineAssignment',
    'quizAssignment/assignmentItem',
    'courseStructure/communication',
    'teacherCourseShell/studentInfo',
    'courseStructure/missingAttendance',
    'courseStructure/takeAttendance',
    'teacherCourseShell/courseMaterials',
    'courseStructure/courseSectionDetails',
    'teacherCourseShell/home',
    'parentLmsFlow/parentStudentList',
    'discussionForum/teacherDiscussion',
    'courseStructure/home',
    'courseStructure/unit',
    'courseStructure/page',
    'courseStructure/label',
    'courseStructure/assignments',
    'courseStructure/assignmentDetails',
    'studentAssignmentSubmission/fileSubmission',
    'studentAssignmentSubmission/onlineTextAssignment',
    'studentAssignmentSubmission/urlfSubmission',
    'studentAssignmentSubmission/home',
    'studentCourseShell/assignment',
    'studentCourseShell/courseMaterials',
    'parentLmsFlow/parentCourseMaterials',
    'parentLmsFlow/parentAssignment',
    'parentLmsFlow/parentGradebook',
    'parentLmsFlow/parentHome',
    'courseStructure/studentGradebook',
    'parentLmsFlow/quizAssignment',
    'courseStructure/material',
    'courseStructure/addAssignment',
    'courseStructure/assignmentSubmission',
    'assignmentType/assignmentCategory',
    'peerReview/teacherPeerReview',
    'studentAssignmentSubmission/quizAssignment',
    'studentAssignmentSubmission/discussionForum',
    'studentAssignmentSubmission/peerReview',
    'teacherAssignmentGrading/fileSubmission',
    'teacherAssignmentGrading/teacherGrading',
    'teacherAssignmentGrading/individualStudent',
    'teacherAssignmentGrading/individualStudents',
    'teacherAssignmentGrading/assignmentGradingURL',
    'teacherAssignmentGrading/quizAssignment',
    'teacherAssignmentGrading/onlineText',
    'assessment/assignmentBank',
    'assessment/assignmentGroup',
    'assessment/assignmentItem',
    'assessment/assignmentItemsChild',
    'assessment/assignmentCategory',
    'assessment/assignmentDetails',
    'assignmentCommentBox/studentTeacherCommunication',
    'assessment/assessmentRubric',
    'assessment/assessmentRubricStudentView',
    'courseStructure/teacherDiscussion',
    'assessment/assessmentRubricTeacherGrading',
    'assessment/assessmentRubricTeacherGrading',
    'courseStructure/courseMaterials',
    'courseStructure/gradeBook'
]
let routeState = {}
for (let i = 0; i < routeList.length; i++) { 
    routeState = {
        ...routeState,
        [routeList[i]]: asyncComponent(() =>
            routeList[i] === 'teacherAssignmentGrading/individualStudents' ? import(`../components/view/teacherAssignmentGrading/individualStudent/homeComponent`).then(module => module.default) : import(`../components/view/${routeList[i]}/homeComponent`).then(module => module.default)
        )
    }
}
const MarkersCommonRoute = asyncComponent(() =>
    import('../components/common/component/markersCommonRoute').then(module => module.default)
);
export class PrivateRoute extends Route {
    render() {
        if (new sessionStore().returnSessionToken() == null) {
            window.location.href = hostArray[new sessionStore().returnHostEnvironment()].login;
        }
        return (
            <div>
                {routeList.map((route, index) => {
                    return <Route key={index} exact path={`/lms/${route}`} component={routeState[route]} />
                })}
                <Route exact path="/lms/commonRoute" component={MarkersCommonRoute} />
            </div>
        )
    }
}
export default connect(
    (state) => { const { sharedStore } = state; return { state: { sharedStore } }; },
    (dispatch) => { return { actions: bindActionCreators(Object.assign({}, sharedActionCreators), dispatch) }; }
)(PrivateRoute);
