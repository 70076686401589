import React from 'react';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    i18n, commonConfig, actionsCommon, ExecuteArgs
} from '../commonLoginindex';
import Validation from '@markerspro/validation-component';
import CommonFuncs from '@markerspro/common-function'
import _ from 'lodash';
const commonFuncObj = new CommonFuncs();

class MarkersFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.state = {
            id: !!this.props.fileId ? this.props.fileId : null,
            fileList: [],
            selectedFileList: [],
            file: null,
            loading: false,
            proccesing: false,
            url: null,
            crop: {
                height: 250,
                width: 250,
                x: 0,
                y: 0
            },
            croppedImageUrl: '',
            downloadFlag: false,
            removeFlag: false,
            removeFileId: null,
            savedFileList: !!this.props.savedFileList && this.props.savedFileList.length > 0 ? this.props.savedFileList : []
        }
    }
    componentDidMount() {
        window.$('.custom-file-input').on('change', function () {
            if (window.$(this).get(0).files.length > 1) {
                window.$(this).next('.custom-file-label').text(window.$(this).get(0).files.length + ' files selected');
            } else {
                var fullpath = window.$(this).val();
                var backslash = fullpath.lastIndexOf("\\");
                var filename = fullpath.substr(backslash + 1);
                window.$(this).next('.custom-file-label').text(filename);
            }
        });
    }
    componentWillUnmount() {
        this.props.onResetProps({ name: 'fileDelete', data: false });
    }
    componentWillReceiveProps(nextProps) {
        //if (this.props.hasOwnProperty('savedFileList') && nextProps.savedFileList && !_.isEqual(nextProps.savedFileList, this.props.savedFileList)) {
        //window.$('[data-toggle="tooltip"]').tooltip();
        //}
        if (nextProps.getFileUrl && !_.isEqual(nextProps.getFileUrl, this.props.getFileUrl) && this.state.downloadFlag) {
            this.setState({ downloadFlag: false }, () => {
                commonFuncObj.download(nextProps.getFileUrl.fileName, nextProps.getFileUrl.contentType, nextProps.getFileUrl.fileContent);
                this.props.onResetProps({ name: 'getFileUrl', data: {} });
            })
        }
        if (nextProps.clearFile) {
            window.$('.custom-file-label').text(i18n.t('selectFile'));
            this.setState({ selectedFileList: [], fileList: [] });
        }
        if (nextProps.fileDelete && this.state.removeFlag) {
            let fileList = [...this.state.fileList];
            let savedFileList = [...this.state.savedFileList];
            _.remove(fileList, (object) => { return !!object.id ? object.id : !!object.fileId ? object.fileId : object.fileid === this.state.removeFileId });
            _.remove(savedFileList, (object) => { return !!object.id ? object.id : !!object.fileId ? object.fileId : object.fileid === this.state.removeFileId });
            this.setState({ removeFlag: false, fileList: fileList, savedFileList: savedFileList, selectedFileList: savedFileList }, () => {
                window.$('.custom-file-label').text(i18n.t('selectFile'));
                this.props.onResetProps({ name: 'fileDelete', data: false });
                if (!!this.props.setFileField) {
                    this.props.setFileField(this.state.selectedFileList)
                }
                if (!!this.props.handleDeleteSuccess) {
                    this.props.handleDeleteSuccess(this.state.removeFileId)
                }
            })
        }
        if (nextProps.savedFileList && Array.isArray(nextProps.savedFileList) && !_.isEqual(nextProps.savedFileList, this.props.savedFileList)) {
            let savedFileList = nextProps.savedFileList;
            this.setState({ savedFileList, selectedFileList: [], fileList: [] });
        }
    }
    /* Starting crop */
    onImageLoaded = (image) => {
        this.imageRef = image;
    }
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    }
    onCropChange = crop => {
        this.setState({ crop });
    }
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )
        return new Promise((resolve, reject) => {
            let dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        })
    }
    handleUpdateProfilePicture = () => {
        if (this.state.croppedImageUrl !== '') {
            this.setState({ proccesing: true }, () => {
                window.$("#imageCroperModal").modal('hide')
                this.setState({ proccesing: false, file: this.state.croppedImageUrl });
            })
        }
    }
    getFile = (event, id) => {
        event.preventDefault();
        this.setState({ downloadFlag: true }, () => {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_DOWNLOAD_FILE + parseInt(id, 10),
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetFile(executedArgument);
        })
    }
    /* Ending crop */
    handleImageChange = (e) => {
        // if (!this.props.isMultiple && !!this.props.isCrop && (!!fileType && fileType.indexOf('image') !== -1) && e.target.files && e.target.files.length > 0) {
        //     window.$("#imageCroperModal").modal('show');
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () =>
        //         this.setState({ url: reader.result })
        //     );
        //     reader.readAsDataURL(e.target.files[0]);
        // } 
        this.setState({ selectedFileList: e.target.files }, () => {
            if (!!this.props.setFileField) {
                this.props.setFileField(this.state.selectedFileList)
            }
            this.generateFileUrl();
        })
    }
    generateFileUrl = () => {
        this.setState(prevState => {
            let prevSelectedFileList = [...prevState.selectedFileList];
            let fileListLength = prevSelectedFileList.length;
            let previewFileList = [];
            if (fileListLength > 0) {
                for (let i = 0; i < fileListLength; i++) {
                    let url = URL.createObjectURL(prevSelectedFileList[i])
                    if (prevSelectedFileList[i].type.indexOf('image') === -1) {
                        let pathSplitted = !!prevSelectedFileList[i].name ? prevSelectedFileList[i].name.split('.') : '';
                        let extension = pathSplitted.pop();
                        //url = process.env.PUBLIC_URL + '/assets/images/file_type/' + extension + '.svg';
                        url = './assets/images/file_type/' + extension + '.svg'
                    }
                    previewFileList.push({ id: 'file' + (i + 1), name: prevSelectedFileList[i].name, thumbnailImage: url, size: prevSelectedFileList[i].size });
                }
            }
            return { ...prevState, fileList: previewFileList }
        });
    }
    removeFile = (event, fileId) => {
        event.preventDefault();
        if (isNaN(fileId)) {
            const fileIndex = _.findIndex(this.state.fileList, { 'id': fileId })
            if (fileIndex !== -1) {
                let fileList = [...this.state.fileList]
                let selectedFileList = [...this.state.selectedFileList]
                fileList.splice(fileIndex, 1)
                selectedFileList.splice(fileIndex, 1)
                this.setState({ removeFlag: false, fileList, selectedFileList }, () => {
                    window.$('.custom-file-label').text(i18n.t('selectFile'));
                    window.$('#fileupload').trigger('reset');
                    if (!!this.props.setFileField) {
                        this.props.setFileField(this.state.selectedFileList)
                    }
                })
            }
        } else {
            if (this.props.announcementId === 0 || this.props.announcementId === undefined) {
                this.setState({ removeFlag: true, removeFileId: parseInt(fileId, 10) }, () => {
                    let executedArgument = new ExecuteArgs(this.props.deleteApiComponent,
                        this.props.deleteApiEndPoint, commonConfig.METHOD_TYPE_POST, parseInt(fileId, 10));
                    this.props.onRemoveFile(executedArgument);
                })
            } else {
                this.setState({ removeFlag: true, removeFileId: parseInt(fileId, 10) }, () => {
                    let executedArgument = new ExecuteArgs(this.props.deleteApiComponent,
                        this.props.deleteApiEndPoint, commonConfig.METHOD_TYPE_POST, { "fileId": Number(fileId), "announcementId": this.props.announcementId });
                    this.props.onRemoveFile(executedArgument);
                })
            }
        }
    }
    render() {
        const { label, isError, isRequired, onlyFile, isPreview, isCrop, isMultiple, fileType, isDownload, fileMaxSize, fileMinSize, isDelete, isValidMultiFileSize, isPreviewInvaildFile = false } = this.props;
        const fileList = !!isMultiple ? this.state.savedFileList.concat(this.state.fileList) : (this.state.fileList.length > 0 ? this.state.fileList : this.state.savedFileList);
        return (
            <React.Fragment>
                <form name="fileupload" id="fileupload">
                    {!!onlyFile ?
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" name="file" multiple={!!isMultiple} onChange={this.handleImageChange} />
                                <label className="custom-file-label text-truncate" for="inputGroupFile01">{i18n.t('selectFile')}</label>
                            </div>
                        </div>
                        :
                        <div className="form-group">
                            <label className="col-form-label">{!!label ? label : i18n.t('file')}{isRequired && <span> *</span>}</label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" name="file" multiple={!!isMultiple}
                                        onChange={this.handleImageChange} />
                                    <label className="custom-file-label text-truncate" htmlFor="inputGroupFile01">{i18n.t('selectFile')}</label>
                                </div>
                            </div>
                        </div>
                    }
                    <Validation data={{
                        field: !!label ? label : i18n.t('file'),
                        errorClass: 'file_error',
                        value: fileList && fileList.length > 0 ? fileList : [],
                        isError: isError,
                        validationType: !!isRequired ? 'file' : 'onlyFileType',
                        fileType: fileType,
                        fileMaxSize: fileMaxSize,
                        fileMinSize: !!fileMinSize ? fileMinSize : 0,
                        isValidMultiFileSize: !!isValidMultiFileSize
                    }} />
                    {(!!isPreview && fileList.length > 0) &&
                        fileList.map((file, index) => {
                            const fileName = !!file.fileName ? file.fileName : file.name;
                            let imgType = ['jpeg', 'jpg', 'bmp', 'gif', 'png'];
                            let currentFileType = !!fileName ? fileName.split(".").pop().toLowerCase() : '';
                            let find = imgType.includes(currentFileType);
                            return fileType === '' || fileType === null || fileType.toLowerCase().indexOf(currentFileType) !== -1 || isPreviewInvaildFile ? <div className={find ? 'preview-image m-b-10 m-r-5 d-inline-block' : 'preview-image m-b-10 m-r-5 d-inline-block preview-icon'} id={!!file.id ? `file-preview-div-${file.id}` : `file-preview-div-${file.fileId}`} data-toggle="tooltip" data-placement="top" title={fileName}>
                                {!!isDelete && <Link to="#" onClick={(e) => this.removeFile(e, !!file.id ? file.id : (!!file.fileId ? file.fileId : file.fileid))} className="deletePreImg"><i className="icon-cancel-circle2"></i></Link>}<img src={!!file.thumbnail ? file.thumbnail : file.thumbnailImage} alt={fileName} className="img-fluid" />
                                <span className="preview text-truncate">
                                    {!!isDownload && <Link to="#" onClick={(e) => this.getFile(e, !!file.id ? file.id : file.fileId)} className="text-white mr-1"><i className="icon-download"></i></Link>}
                                    {fileName}
                                </span>
                            </div> : ''
                        })
                    }
                    {(!isMultiple && !!isCrop && (!!fileType && fileType.indexOf(fileList[0].type) !== -1)) &&
                        <div className="modal fade" id="imageCroperModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.url && (
                                            <ReactCrop
                                                src={this.state.url}
                                                crop={this.state.crop}
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        {this.state.proccesing ?
                                            <button type="button" className="btn btn-primary" disabled={true}>{i18n.t('proccesing')}</button> :
                                            <button type="button" className="btn btn-primary" onClick={() => this.handleUpdateProfilePicture()}>{i18n.t('upload')}</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        getFileUrl: state.commonStore.getFileUrl,
        fileDelete: state.commonStore.fileDelete
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetFile: (executeArgs) => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'file url')),
        onRemoveFile: (executeArgs) => dispatch(actionsCommon.callCommonDeleteApi(executeArgs, 'file')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersFileUpload)
