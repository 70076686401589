import React from "react";
import * as Datetime from 'react-datetime';

const MarkersTimeField = React.memo((props) => {
    const { attribute, divClassName, onlyField, title, required, isError } = props;
    const _convertDatetimeFieldToTime = (event) => {
        let value = "";
        if (!!event._d) {
            let hours = event._d.getHours();
            let minutes = "0" + event._d.getMinutes();
            let ampm = 'AM';
            if (hours === 0) {
                hours = 12;
                ampm = 'AM';
            } else if (hours === 12) {
                hours = 12;
                ampm = 'PM';
            } else if (hours > 12) {
                hours = hours - 12;
                ampm = 'PM';
            }
            value = hours + ':' + minutes.substr(-2) + ' ' + ampm;
        }
        attribute.onChange({ target: { ...event, name: attribute.name, type: 'time1', value } })
    }
    const fieldResult = <React.Fragment>
        <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text group-height"><i className="icon-watch"></i></span>
            </div>
            <Datetime
                dateFormat={false} value={!!attribute.value ? attribute.value : ''}
                onChange={(e) => _convertDatetimeFieldToTime(e)}
                inputProps={{
                    className: !!attribute.className ? attribute.className : 'form-control col-lg-12', name: attribute.name,
                    placeholder: !!attribute.placeholder ? attribute.placeholder : "HH:MM", readOnly: true, disabled: false
                }}
            />
        </div>
    </React.Fragment>
    return (
        !!onlyField ?
            fieldResult :
            <div className={!!divClassName ? divClassName : "form-group"}>
                <label className="col-form-label">{title}{!!required && <span> *</span>}</label>
                {fieldResult}
            </div>
    )
})
export default MarkersTimeField;
