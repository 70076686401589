import * as actionTypesLogin from "../actions/actionTypesLogin"
import { updateObject } from '../../../shared/store/utility';
import commonConfig from '@markerspro/common-config';
import sessionStore from '@markerspro/session-store';
const initialState = {
    token: '',
    isLoading: false,
    isAuthenticated: false,
    isResourcesLoaded: false,
    errorMessage: '',
    notificationList: [],
    isMarkAsRead: false,
    saveUserPreference: false,
    getForgotPasswordOtp: {},
    confirmForgotPasswordOtp: {},
    saveConfirmPassword: false,
    getUserPreferenceOtp: {},
    getLoginSsoUrl: '',
    getSystemMaintenanceByUserId: {}
    // menuList: []
};
const handleFail = (state, action) => {
    new sessionStore().cleanSessionStore();
    return updateObject(state, {
        isLoading: false,
        isAuthenticated: false,
        isResourcesLoaded: false,
        errorMessage: action.error,
        isMarkAsRead: false,
        saveUserPreference: false
    });
};
const handleLoginPending = (state, action) => {
    return updateObject(state, {
        isLoading: false,
        isAuthenticated: false,
        isLoginPending: action.isLoginPending
    });
};
const handleLoginFail = (state, action) => {
    new sessionStore().cleanSessionStore();
    return updateObject(state, {
        isLoading: false,
        isAuthenticated: null,
        isResourcesLoaded: false,
        errorMessage: action.error,
        isMarkAsRead: false,
        saveUserPreference: false
    });
};

const validateLoginSucess = (state, action) => {
    var isAuthenticated = false;
    var userObject = action.user.data;
    if (userObject) {
        isAuthenticated = true;
        new sessionStore().storeSecureData(commonConfig.SESSION_DISTRICTID, "1001");
        new sessionStore().storeSecureData(commonConfig.SESSION_USER_ID, userObject.activeUserID);
        new sessionStore().storeSecureData(commonConfig.SESSION_USER_NAME, userObject.userName);
        new sessionStore().storeSecureData(commonConfig.SESSION_TOKEN, userObject.userToken.token);
        new sessionStore().storeSecureData(commonConfig.SESSION_DATE_FORMAT, "MMM DD,YYYY");
        new sessionStore().storeSecureData(commonConfig.SESSION_PHONE_FORMAT, "(###) ###-####");
        new sessionStore().storeSecureData(commonConfig.SESSION_IDLETIMER, 60);
        new sessionStore().storeSecureData(commonConfig.SESSION_SELECT_SCHOOL, '');
        new sessionStore().storeSecureData(commonConfig.SESSION_NEXT_CLASS_SHOW, 5);
    }
    return updateObject(state, {
        loading: false,
        token: userObject.UserToken,
        isAuthenticated: isAuthenticated,
        errorMessage: userObject.userToken.token
    });
};

const getLoginUrlSucess = (state, action) => {
    var userObject = action.loginUrl;
    if (userObject) {
        new sessionStore().storeSecureData(commonConfig.SESSION_LOGIN_URL, userObject.data.loginObject);
        new sessionStore().storeSecureData(commonConfig.SESSION_ENVIRONMENT, userObject.data.hostEnvironment);
    }
    return updateObject(state, {
        loading: false,
        getLoginSsoUrl: userObject.data.loginObject
    });
};


const getAllMenusSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        menuList: action.custom.data,
        message: action.custom.message
    });
};

const getUserMenusSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        userMenuList: action.custom.data,
        message: action.custom.message
    });
};

const getAllNotificationSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        notificationList: action.custom.data,
        message: action.custom.message,
        isMarkAsRead: false
    });
};
const updateMarkAsReadNotificationSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        isMarkAsRead: action.custom.data > 0 ? true : false,
        message: action.custom.message
    });
};
const updateUserPreferenceSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        saveUserPreference: action.custom.data,
        message: action.custom.message
    });
};
const storeResourcesToSession = (state, action) => {
    var resources = action.resources.data;
    if (resources !== null && resources !== "") {
        return updateObject(state, {
            isAuthenticated: true,
            isResourcesLoaded: true
        });
    }
};
const storeLastUserToSession = (state, action) => {
    var sessionDetails = action.resources.data;
    if (sessionDetails !== null && sessionDetails !== "") {
        new sessionStore().storeSecureData(commonConfig.SESSION_LAST_USER_DETAILS, JSON.stringify(sessionDetails));
        return updateObject(state, {
            isAuthenticated: true,
            isResourcesLoaded: true
        });
    }
};

const generateStudentPasswordSchedulerSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        isMarkAsRead: action.custom.data,
        message: action.custom.message
    });

};
const setLastUserSessionToCache = (state, action) => {
    return updateObject(state, {
        loading: false,
        isMarkAsRead: true
    });
};
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.custom.data
    });
};
const reducerLogin = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case actionTypesLogin.VALIDATE_LOGIN_SUCCESS: return validateLoginSucess(state, action);
        case actionTypesLogin.GET_RESOURCES_SUCESS: return storeResourcesToSession(state, action);
        case actionTypesLogin.HANDLE_LOGIN_FAIL: return handleLoginFail(state, action);
        case actionTypesLogin.HANDLE_LOGIN_PENDING: return handleLoginPending(state, action);
        case actionTypesLogin.GET_ALL_MENUS_SUCCESS: return getAllMenusSuccess(state, action);
        case actionTypesLogin.GET_USER_MENUS_SUCCESS: return getUserMenusSuccess(state, action);
        case actionTypesLogin.GET_ALL_NOTIFICATION_SUCCESS: return getAllNotificationSuccess(state, action);
        case actionTypesLogin.UPDATE_MARKASREAD_NOTIFICATION_SUCCESS: return updateMarkAsReadNotificationSuccess(state, action);
        case actionTypesLogin.UPDATE_USER_PREFERENCE_SUCCESS: return updateUserPreferenceSuccess(state, action);
        case actionTypesLogin.GET_LAST_SESSION_SUCESS: return storeLastUserToSession(state, action);
        case actionTypesLogin.SET_LAST_SESSION_SUCESS: return setLastUserSessionToCache(state, action);
        case actionTypesLogin.GET_FORGOT_PASSWORD_OTP_SUCCESS: return handleSuccess(state, action, 'getForgotPasswordOtp');
        case actionTypesLogin.CONFIRM_FORGOT_PASSWORD_OTP_SUCCESS: return handleSuccess(state, action, 'confirmForgotPasswordOtp');
        case actionTypesLogin.SAVE_CONFIRM_PASSWORD_SUCCESS: return handleSuccess(state, action, 'saveConfirmPassword');
        case actionTypesLogin.GET_USER_PREFERENCE_SUCCESS: return handleSuccess(state, action, 'getUserPreferenceOtp');
        case actionTypesLogin.GET_SYSTEM_MAINTENANCE_USER_ID_SUCCESS: return handleSuccess(state, action, 'getSystemMaintenanceByUserId');
        case actionTypesLogin.GENERATE_STUDENT_SCHEDULER_PASSWORD_SUCCESS: return generateStudentPasswordSchedulerSuccess(state, action);
        case commonConfig.LOGIN_STORE_RESET: return handleSuccess(state, action, action.title);
        case actionTypesLogin.GET_LOGIN_URL_SUCCESS: return getLoginUrlSucess(state, action);
        case actionTypesLogin.HANDLE_LOGIN_URL_FAIL: return handleFail(state, action);
        default: return state;
    }
};
export default reducerLogin;
