export class localConfig {
    static YES_VALUE = "Y";
    static NO_VALUE = "N";
    static LOOKUP_INDENTATION = 'INDENTATION';
    static LOOKUP_CUSTOM_SORT_ORDER = 32202;
    static LOOKUP_LMS_ACTIVITY_TYPE = 'LMS_ACTIVITY_TYPE';
    static LOOKUP_LMS_SUBMISSION_TYPE = 'LMS_SUBMISSION_TYPE';
    static IS_LMS = 'lms';
    static COMPONENT_ASSESSMENT = "assessment";
    static CURRENT_SCHOOL_SESSION_END_DATE = 'currentSchoolSessionEndDate';
    static LOOKUP_COURSE_APPROVAL_STATUS = 'APPROVAL_STATUS';
    static LOOKUP_LMS_CS_COURSE_TO_SHOW = 'LMS_CS_COURSE_TO_SHOW';
    static LOOKUP_LMS_SORT_ORDER = 'LMS_SORT_ORDER';
    static LOOKUP_COURSE_TYPE = 'COURSE_TYPE';
    static LOOKUP_LMS_FILE_TYPE = 'LMS_FILE_TYPE';
    static LOOKUP_FILE_SIZE_TYPE = 'FILE_SIZE_TYPE';
    static IS_HIDE = 'hide';
    static LOOKUP_GRADETYPE_GRADE_SCALE = 17402;
    static LMS_ACTIVITY_ASSIGNMENT = 31901;
    static LMS_ACTIVITY_ASSIGNMENT_ASSESSMENT = 32901;
    static LMS_ACTIVITY_ASSIGNMENT_DISCUSSION = 32903;
    static LMS_ACTIVITY_QUIZ = 31902;
    static LMS_ACTIVITY_QUIZ_ASSESSMENT = 32902;
    static LMS_ACTIVITY_DISCUSSION = 31903;
    static LMS_ACTIVITY_PAGE = 31904;
    static LMS_ACTIVITY_LABEL = 31905;
    static LMS_ACTIVITY_MATERIAL = 31906;
    static LMS_COURSE_MATERIAL = 31907;
    static FILE_TYPE_COURSE_MATERIAL = 'docx|jpg|png|doc|pdf|jpeg|pptx|ppt|xls|xlsx|mp3|mp4';
    /* above variables are added in package */
    static LOOKUP_LMS_PUSH_NOTIFICATION_TYPE = 'LMS_PUSH_NOTIFICATION_TYPE';
    static RGX_LMS_MATERIAL_TITLE = /^(?!\s)[0-9a-zA-Z-,/'.\s\^\']+$/i;
    static RGX_LMS_EMAIL_BODY = /^(?!\s)[0-9a-zA-Z-,<>/'.%#&@&nbsp;\s\^\']+$/i;
    static RGX_LMS_MATERIAL_COMMENT = /^(?!\s)[0-9a-zA-Z\s\-.,'\/]+$/i;
    static LOOKUP_STUDY_MATERIAL_TYPE = 'STUDY_MATERIAL_TYPE';
    static RGX_LMS_FORUM_TITLE = /^(?!\s)[0-9a-zA-Z\s\-&',/:]+$/i;
    /*--- 26/08/2021 ---*/
    static SUBMISSION_TYPE_ONLINE_TEXT = 32101;
    static SUBMISSION_TYPE_FILE = 32102;
    static SUBMISSION_TYPE_IN_CLASS = 32103;
    static SUBMISSION_TYPE_URL = 32104;
    static SUBMISSION_TYPE_TEXT_FILE = 32105;
    static UNIT_DESC = /^(?!\s)[0-9a-zA-Z\s\‘,^</>]+$/i;
    static UNIT_DESC_ONLINE_TEST = /^(?!\s)[0-9a-zA-Z&@/:-\s\.‘,^&amp;</>]+$/i;
    static SUBMISSION_FILE_SIZE_IN_KB = 32501;
    static SUBMISSION_FILE_SIZE_IN_MB = 32502;
    static ANNOUNCEMENT_TYPE_CRITICAL = 16001;
    static ANNOUNCEMENT_TYPE_NORMAL = 16002;
    static ANNOUNCEMENT_TYPE_HIGH = 16003;
    static DefaultTimeFormat = "00:00:00";
    static REGEX_AL_NM_SP_DASH_AND_QT_SLASH = /^(?!\s)[0-9a-zA-Z\s\-&'/]+$/i; //a-z, A-Z, 0-9, space, -/'&
    static WEIGHT_VAL = /^(100(?:\.00)?|0(?:\.\d\d)?|\d?\d(?:\.\d\d)?)$/;
    static FILE_TYPE_DOC_PDF_EXCEL = 'docx|doc|pdf|xlsx';
    static FILE_SIZE_10MB = 10485760;
    static FILE_SIZE_100MB = 104857600;
    static REGEX_AL_NM_SP_DASH_DOT_PER_HASH_AND_SLASH = /^[?!\s0-9a-zA-Z\s\-.,%#&nbsp/:"";''()</><>]+$/i; //a-z, A-Z, 0-9, space, -.%#&/
    static RUBRIC_TITLE = /^(?!\s)[0-9a-zA-Z\s\-._]+$/i; //special characters included a-z, A-Z, 0-9, space, ._-
    static RUBRIC_DESCRIPTION = /^(?!\s)[0-9a-zA-Z\s\-._&/#]+$/i; //special characters included a-z, A-Z, 0-9, space, ._-&/#
    static LABEL_TITLE = /^(?!\s)[0-9a-zA-Z\s\&-.:;@*#_/]+$/i;
    static LMS_TITLE = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i; //special characters included a-z, A-Z, 0-9, space, -_/:'&
    static PAGE_TITLE = /^(?!\s)[a-zA-Z\d\/_&.:-\s]+$/i;
    static PAGE_TITLE_TWO = /^(?!\s)[a-zA-Z\d\/_&.,#&nbsp:-@\s]+$/i;
    static MATERIAL_TITLE = /^[a-zA-Z\d\/_&@#,.\-:\s]+$/i;
    static COPY_TITLE = /^(?!\s)[0-9a-zA-Z\s\-_:&'/]+$/i
    static SESSION_PROFILE_PICTURE = 'markersProfilePicture';
    static SESSION_TEACHER_EDIT_ATTENDANCE = 'markersTeacherEditAttendance';
    static SESSION_TEACHER_EDIT_ALL_ASSIGNMENT = 'markersTeacherEditAllAssignment';
}
export default localConfig;
