import React from 'react';
import Validation from '@markerspro/validation-component';
import i18n from '@markerspro/i18n';
import Select from "react-select";
import _ from 'lodash';
class MarkersSelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            optionArray: this.generateSelectedOption()
        }
    }
    componentDidMount() {
        if (this.props.attribute.hasOwnProperty('value')) {
            this.getMultiSelectSelectedOption(this.props.attribute.value)
        }
    }
    componentWillReceiveProps(nextProps) {
        // if (this.props.attribute.hasOwnProperty('value'))
        //     this.getMultiSelectSelectedOption(nextProps.attribute.value)
        if (!!nextProps.selectOptions) {
            this.setState({ optionArray: this.generateSelectedOption() })
        }
    }
    getMultiSelectSelectedOption = (selectedValue) => {
        if (this.props.attribute.hasOwnProperty('isMulti') && !!this.props.attribute.isMulti && !!selectedValue && selectedValue.length !== 0) {
            const selectedValueArray = Array.isArray(selectedValue) ? selectedValue : selectedValue.split(",")
            const selectedOption = _.filter(this.state.optionArray, (object) => { return selectedValueArray.indexOf(object.value) !== -1 });
            this.setState({ selectedOption: selectedOption.length !== 0 ? selectedOption : null })
        }
    }
    generateSelectedOption = () => {

        if (!!this.props.selectOptions && this.props.selectOptions.length === 0) {
            return [];
        }

        else {
            let fieldOptions = [];

            var optionsSelect = this.props.selectOptions.split("#");
            for (let i = 0; i < optionsSelect.length; i++) {
                let optionObj = {};
                let optionValue = optionsSelect[i].split(",");
                optionObj['value'] = optionValue[0];
                optionObj['label'] = optionValue[1];
                fieldOptions.push(optionObj);

            }
            return fieldOptions;
        }
     
    }

    handleChangeMultiDropDown = (selectedOption, event) => {
        let selectedValue = !!selectedOption && selectedOption.length !== 0 ?
            selectedOption.map((data) => {
                return (data.value)
            }) : []
        let selectedTitle = !!selectedOption && selectedOption.length !== 0 ?
            selectedOption.map((data) => {
                return (data.label)
            }) : []
        this.setState({ selectedOption }, () => {
            const target = {
                ...event,
                type: 'multiSelect',
                value: selectedValue.toString(),
                valueTitle: selectedTitle.toString(),
                valueArray: selectedValue
            }
            this.props.attribute.onChange({ target })
        })
    }
    render() {
        const { title, isError, required, onlyField, divClassName, removeFirstOption } = this.props;
        const { optionArray, selectedOption } = this.state;
        let attribute = { ...this.props.attribute }
        // delete attribute.value;
        if (attribute.hasOwnProperty('isMulti') && !!attribute.isMulti) {
            attribute = { ...attribute, options: optionArray, value: selectedOption };
            attribute.onChange = this.handleChangeMultiDropDown;
            delete attribute.className;
        } else {
            if (!attribute.hasOwnProperty('className')) {
                attribute = { ...attribute, className: 'custom-select' };
            }
        }
        const firstOptionTitle = this.props.hasOwnProperty('firstOptionTitle') && !!this.props.firstOptionTitle ? this.props.firstOptionTitle : i18n.t('select');
        const fieldResult = <React.Fragment>
            {attribute.hasOwnProperty('isMulti') && !!attribute.isMulti
                ? <Select {...attribute} />
                : <select {...attribute}>
                    {!!removeFirstOption ? null : <option value="">{firstOptionTitle}</option>}
                    {(!!optionArray.length && optionArray.length !== 0) && optionArray.map((item) => {
                        return <option value={item.value}>{item.label}</option>
                    })}
                </select>
            }
            {(!!required) && <Validation data={{
                field: title,
                errorClass: attribute.name + '_error',
                value: !!attribute.value ? attribute.value : '',
                isError: isError,
                validationType: 'selectfield'
            }} returnValidation={this.functionValidation} />}
        </React.Fragment>
        return (
            !!onlyField ?
                fieldResult :
                <div className={!!divClassName ? divClassName : "form-group"}>
                    <label className="col-form-label">{title}{!!required && <span> *</span>}</label>
                    {fieldResult}
                </div>
        )
    }
}
export default MarkersSelect;