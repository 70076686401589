import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { i18n, MarkersLink, usePrevious, Validation, actions, endPointConfig, commonConfig, CommonFuncs, MarkersCommonSelect, ExecuteArgs, MarkersDelete } from '../../commonLmsIndex';
import { findIndex, filter } from 'lodash';
import Moment from 'react-moment';
import { initialFields, requiredFields, integerFields, initialUpdateFileFields, UNIT_DESCID } from './config';

function MarkersFileManager(props) {
    const commonFuncObj = new CommonFuncs();
    const [state, setState] = useState({
        isSave: false,
        isEdit: false,
        isError: 0,
        isErrorFolderName: false,
        selectedFileList: [],
        downloadFlag: false,
        removeFlag: false,
        removeFileId: null,
        savedFileList: [],
        saveFileFolderFlagType: '',
        fileList: [],
        fields: {
            ...initialFields
        },
        updateFileFields: {
            ...initialUpdateFileFields
        },
        tempFiles: [],
        viewType: 1, //1 for grid,2 for list, 3 for folder
        currentActiveFolderId: 0,
        currentActiveFolderName: '',
        viewFileFolderPath: [{ id: 0, title: '/' }],
        moveToFolderFlag: false,
        downloadFileFlag: false,
        downloadFileId: 0,
        updateFilePathFlag: false,
        getFolderStructureFlag: false,
        closeModalFlag: false,
        deleteId: null,
        deleteComponent: '',
        modalId: '',
        getAllFolderPathFlag: false,
        downloadFolderFlag: false,
        downloadFolderId: 0,
        downloadFolderTitle: '',
        updateFolderFlag: false,
        tempDeleteIndex: null,
        clearTempFiles: false,
        tempDeleteId: null
    })

    const handleChange = (event, field) => {
        let fields = { ...state[field] }
        const fieldName = event.target.name;
        if (integerFields.indexOf(event.target.name) !== -1) {
            if (isNaN(parseFloat(event.target.value))) {
                fields[fieldName] = null;
            } else {
                fields[fieldName] = parseInt(event.target.value, 10);
            }
        } else if (event.target.type === "checkbox") {
            fields[fieldName] = event.target.checked;
        } else {
            fields[fieldName] = event.target.value;
        }
        setState({ ...state, [field]: fields });
    }

    const checkIfFolderNameExists = (fileFolderList, currentActiveFolderId, name) => {
        let fields = !!fileFolderList ? [...fileFolderList] : [];
        let returnFolderFlag = false;
        !!fields && fields.forEach((data, key) => {
            if (currentActiveFolderId === 0) {
                if (data.title.trim() === name.trim()) {
                    returnFolderFlag = true;
                    return false;
                } else {
                    returnFolderFlag = false;
                }
            } else {
                if (!!data.folderId && data.folderId === currentActiveFolderId) {
                    checkIfFolderNameExists(data.fileFolderDetails, currentActiveFolderId, name);
                } else {
                    if (data.title.trim() === name.trim()) {
                        returnFolderFlag = true;
                        return false;
                    } else {
                        returnFolderFlag = false;
                    }
                }
            }
        })
        return returnFolderFlag;
    }

    const checkIfFileNameExists = (fileFolderList, currentActiveFolderId, name) => {
        let fields = !!fileFolderList ? [...fileFolderList] : [];
        let returnFileFlag = 0;
        !!fields && fields.forEach((data, key) => {
            if (currentActiveFolderId === 0) {
                if (data.title.trim() === name.trim()) {
                    returnFileFlag++;
                    return false;
                }
            } else {
                if (!!data.folderId && data.folderId === currentActiveFolderId) {
                    checkIfFileNameExists(data.fileFolderDetails, currentActiveFolderId, name);
                } else {
                    if (data.title.trim() === name.trim()) {
                        returnFileFlag++;
                        return false;
                    }
                }
            }
        })
        return returnFileFlag;
    }
    //This function is called for create folder modal show.
    const createFolder = () => {
        let fields = { ...initialFields };
        fields.uniqueId = props.uniqueId;
        setState({ ...state, fields: fields, modalId: 'createFolderModal' });
        window.$("#createFolderModal").modal("show");
    }
    const closeModal = (e, modalId, fields, closeModalFlag = false) => {
        let fieldObj = (fields === 'fields') ? { ...initialFields } : { ...initialUpdateFileFields };
        let setStateObj = { ...state, [fields]: fieldObj }
        if (!!closeModalFlag) {
            setStateObj = { ...setStateObj, closeModalFlag: false }
        }
        if (!!state.modalId) {
            setStateObj = { ...setStateObj, modalId: '' }
        }
        setState(setStateObj);
        window.$("#" + modalId).modal("hide");
    }
    //This function is called for edit file modal show.
    const editFile = (e, fileName, authorName, fileId, id, materialFileId, createdOn, lastModifiedOn, fileSize) => {
        let updateFileFields = { ...state.updateFileFields };
        updateFileFields['id'] = props.fromPage === "assignment" ? id : materialFileId;
        updateFileFields['fileId'] = fileId;
        updateFileFields['title'] = fileName;
        updateFileFields['authorName'] = authorName;
        updateFileFields['createdOn'] = createdOn;
        updateFileFields['lastModifiedOn'] = lastModifiedOn;
        updateFileFields['fileSize'] = fileSize;
        setState({ ...state, updateFileFields, modalId: 'editFileModal' });
        window.$("#editFileModal").modal("show");
    }

    const editFolder = (e, fileName, folderId) => {
        let fields = { ...initialFields };
        fields['title'] = fileName;
        fields['id'] = folderId;
        setState({ ...state, fields: fields, modalId: 'createFolderModal' });
        window.$("#createFolderModal").modal("show");
    }

    useEffect(() => {
        window.$(document).on('click', 'ul.marking-period-tree li .panel-element .collapse-button', function (e) {
            e.preventDefault();
            window.$(this).closest('.panel-default').toggleClass('show-info');
        });
        window.$(document).on('click', '.main-toogle-btn  a.collapse-all-btn', function (e) {
            e.preventDefault();
            window.$(".marking-period-tree li").addClass('collapse-tree');
        });
        window.$(document).on('click', '.main-toogle-btn  a.expand-all-btn', function (e) {
            e.preventDefault();
            window.$(".marking-period-tree li").removeClass('collapse-tree');
        });
        window.$(document).on('click', 'ul.marking-period-tree  a.toogle-btn', function (e) {
            e.preventDefault();
            window.$(this).closest('li').toggleClass('collapse-tree');
        });
        window.$("#createFolderModal").on("hidden.bs.modal", function () {
            if (window.$('.modal:visible').length > 0) {
                window.$('body').addClass('modal-open');
            }
        });
        window.$("#editFileModal").on("hidden.bs.modal", function () {
            if (window.$('.modal:visible').length > 0) {
                window.$('body').addClass('modal-open');
            }
        });
        return () => {
            props.onResetProps({ name: 'materialsFolderList', data: [] });
        }
    }, []);

    useEffect(() => {
        if (props.activityId !== 0) {
            getAllFolderPath();
        }
    }, [props.activityId])

    const getAllFolderPath = () => {
        let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
            props.getFolderListEndpoint + props.activityId,
            commonConfig.METHOD_TYPE_GET);
        props.onGetMaterialsFolderListByActivityId(executedArgument);
    }

    const fileFolderTempSaveJson = (type) => {
        let fields = { ...state.fields };
        fields.uniqueId = props.uniqueId;
        fields.isFolderOnly = type === 'folder' ? true : false;
        fields.parentFolderId = !!state.currentActiveFolderId ? state.currentActiveFolderId : 0;
        props.onResetProps({ name: 'saveFileFolderMaterials', data: false });
        props.onResetProps({ name: 'saveFolderMaterials', data: false });
        props.onResetProps({ name: 'saveFileMaterials', data: false });
        if (props.editId !== 0) {
            if (type === 'folder') {
                let folderFields = { title: '', parentFolderId: 0, activityId: 0 };

                folderFields['parentFolderId'] = fields.parentFolderId === 0 ? null : fields.parentFolderId;
                if (props.fromPage === "assignment") {
                    folderFields['assignmentId'] = props.activityId;
                    folderFields['folderName'] = fields.title;
                } else {
                    folderFields['activityId'] = props.activityId;
                    folderFields['title'] = fields.title;
                }

                let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                    props.saveFolderEndpoint, commonConfig.METHOD_TYPE_POST, folderFields);
                props.onSaveFolderSuccess(executedArgument);
            } else {
                const fileData = new FormData()
                if (props.fromPage === "assignment") {
                    fields.AssignmentId = props.activityId;
                } else {
                    fields.materialId = props.materialId;
                }
                fields['folderId'] = fields.parentFolderId;
                fileData.append('inputData', JSON.stringify(fields));
                fileData.append('component', endPointConfig.COMPONENT_LMS);
                fileData.append('endpoint', props.saveFileEndpoint);
                if (!!state.tempFiles) {
                    for (let file of state.tempFiles) {
                        fileData.append("Files", file);
                    }
                }
                props.onSaveFileSuccess(fileData);
            }
        } else {
            const fileData = new FormData()
            fileData.append('inputData', JSON.stringify(fields));
            fileData.append('component', endPointConfig.COMPONENT_LMS);
            fileData.append('endpoint', props.tempFileFolderSaveEndPoint);
            if (type === 'file') {
                if (!!state.tempFiles) {
                    for (let file of state.tempFiles) {
                        fileData.append("Files", file);
                    }
                }
            } else {
                fileData.append('Files', JSON.stringify([]))
            }
            props.onFileFolderSaveSuccess(fileData);
        }
    }

    const handleSubmit = (e, type) => {
        const validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(state.fields, requiredFields);
        if (submitStatus === 0) {
            setState({ ...state, isError: 1, isErrorFolderName: false });
        } else {
            let checkIfExists = checkIfFolderNameExists(state.savedFileList, state.currentActiveFolderId, state.fields.title);
            if (!!checkIfExists) {
                setState({ ...state, isError: 1, isErrorFolderName: true });
            } else {
                if (!!state.fields.id) {
                    setState({ ...state, isError: 0, updateFolderFlag: true, isErrorFolderName: false });
                } else {
                    setState({ ...state, isError: 0, saveFileFolderFlagType: type, isErrorFolderName: false });
                }
            }
        }
    }

    useEffect(() => {
        if (!!state.updateFolderFlag) {
            let fields = { ...state.fields };
            if (props.fromPage === "assignment") {
                fields['folderName'] = fields.title;
            }
            fields.actualFolderId = null;
            fields.actualParentFolderId = null;
            fields.parentFolderId = null;
            fields.isFolderOnly = true;
            let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                props.updateFolderEndpoint, commonConfig.METHOD_TYPE_POST, fields);
            props.onSaveFolderSuccess(executedArgument);
        }
    }, [state.updateFolderFlag])

    const handleFilePathUpdate = (e) => {
        const validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(state.updateFileFields, requiredFields);
        if (submitStatus === 0) {
            setState({ ...state, isError: 1 });
        } else {
            setState({ ...state, isError: 0, updateFilePathFlag: true });
        }
    }

    useEffect(() => {
        if (!!state.updateFilePathFlag) {
            let fields = state.updateFileFields;
            if (props.fromPage === "assignment") {
                fields.AssignmentId = props.activityId;
            } else {
                fields.materialId = props.materialId;
            }
            let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                props.fileUpdateEndPoint, commonConfig.METHOD_TYPE_POST, fields);
            props.onUpdateFilePathSuccess(executedArgument);
        }
    }, [state.updateFilePathFlag])

    useEffect(() => {
        if (props.saveFilePath && !!props.saveFilePath.id && !!state.updateFilePathFlag) {
            props.onResetProps({ name: 'saveFilePath', data: false });
            props.onResetProps({ name: 'materialsFolderStructureList', data: false });
            setState({ ...state, updateFilePathFlag: false, getFolderStructureFlag: true });
        }
    }, [props.saveFilePath])

    const getFolderStructure = () => {
        let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
            props.getFolderStructureEndPoint + props.materialId,
            commonConfig.METHOD_TYPE_GET);
        props.onGetFolderStructureSuccess(executedArgument);
    }

    useEffect(() => {
        if (!!state.getFolderStructureFlag) {
            getFolderStructure();
            getAllFolderPath();
        }
    }, [state.getFolderStructureFlag])

    useEffect(() => {
        if (!!state.getFolderStructureFlag && !!props.materialsFolderStructureList && (props.materialsFolderStructureList.length > 0 || props.materialsFolderStructureList.length === 0)) {
            if (!!state.currentActiveFolderId) {
                findFileFolder(props.materialsFolderStructureList, state.currentActiveFolderId);
            } else {
                let setStateObj = { ...state, savedFileList: props.materialsFolderStructureList, getFolderStructureFlag: false }
                setState(setStateObj);
            }
            props.setFileManager(props.materialsFolderStructureList, state.currentActiveFolderId, 'resetFolderStructure')
            if (!!state.modalId) {
                if (state.modalId === 'createFolderModal') {
                    closeModal('', state.modalId, 'fields', state.closeModalFlag)
                } else {
                    closeModal('', state.modalId, 'updateFileFields', state.closeModalFlag)
                }
            }

        }
    }, [props.materialsFolderStructureList])

    useEffect(() => {
        if (props.saveFileFolderMaterials && !!props.saveFileFolderMaterials.id && !!state.saveFileFolderFlagType) {
            if (state.saveFileFolderFlagType === 'folder') {
                if (!!props.setFileManager && !!state.fields && Object.keys(state.fields).length !== 0) {
                    let folderList = [];
                    let fields = { ...state.fields };
                    fields.title = fields.title;
                    fields.isFolderOnly = true;
                    fields.uniqueId = props.uniqueId;
                    fields.folderId = props.saveFileFolderMaterials.id;
                    fields.parentFolderId = !!state.currentActiveFolderId ? state.currentActiveFolderId : 0;
                    fields['isNew'] = true;
                    folderList.push({ ...fields })
                    props.setFileManager(folderList, state.currentActiveFolderId)
                }
                closeModal('', state.modalId, 'fields')
            } else {
                let tempFiles = [];
                if (!!state.tempFiles) {
                    for (let file of state.tempFiles) {
                        let url = '';
                        if (file.type.indexOf('image') !== -1) {
                            //let pathSplitted = file.name.split('.');
                            //let extension = pathSplitted.pop();
                            //url = './assets/images/file_type/' + extension + '.svg'
                            url = URL.createObjectURL(file)
                        }
                        tempFiles.push({ title: file.name, url: url, id: props.saveFileFolderMaterials.id, isNew: true, folderId: 0, uniqueId: props.uniqueId, isFolderOnly: false, parentFolderId: !!state.currentActiveFolderId ? state.currentActiveFolderId : 0 })
                    }
                }
                props.setFileManager(tempFiles, state.currentActiveFolderId)
            }

            let fields = { ...initialFields };
            fields.uniqueId = props.uniqueId;
            setState({ ...state, fields: fields, saveFileFolderFlagType: '', tempFiles: [] });

        }
    }, [props.saveFileFolderMaterials])

    useEffect(() => {
        if (props.saveFolderMaterials && !!props.saveFolderMaterials.id && !!state.saveFileFolderFlagType) {
            setState({ ...state, saveFileFolderFlagType: '', getAllFolderPathFlag: true });
            getAllFolderPath();
        } else if (props.saveFolderMaterials && !!props.saveFolderMaterials.id && !!state.updateFolderFlag) {
            setState({ ...state, updateFolderFlag: false, getAllFolderPathFlag: true });
            getAllFolderPath();
        }
    }, [props.saveFolderMaterials])

    useEffect(() => {
        if (props.materialsFolderList && props.materialsFolderList.length > 0 && state.getAllFolderPathFlag) {
            props.onResetProps({ name: 'materialsFolderStructureList', data: false });
            setState({ ...state, getAllFolderPathFlag: false, getFolderStructureFlag: true });
        }
    }, [props.materialsFolderList])

    useEffect(() => {
        if (props.saveFileMaterials && !!props.saveFileMaterials.id && !!state.saveFileFolderFlagType) {
            props.onResetProps({ name: 'materialsFolderStructureList', data: false });
            setState({ ...state, saveFileFolderFlagType: '', getFolderStructureFlag: true });
        }
    }, [props.saveFileMaterials])

    useEffect(() => {
        if (!!state.saveFileFolderFlagType) {
            fileFolderTempSaveJson(state.saveFileFolderFlagType);
        }
    }, [state.saveFileFolderFlagType])

    const prevSavedFileList = usePrevious(props.savedFileList);

    const findFileFolder = (fileFolderList, currentActiveFolderId) => {
        let fields = [...fileFolderList]
        let stopFileFolderFlag = false;
        !!fields && fields.forEach((data, key) => {
            if (!!data.folderId && data.folderId === currentActiveFolderId) {
                let setStateObj = { ...state, savedFileList: data.fileFolderDetails, stopFileFolderFlag: true };
                if (!!state.moveToFolderFlag) {
                    setStateObj = { ...setStateObj, moveToFolderFlag: false }
                }
                if (!!state.getFolderStructureFlag) {
                    setStateObj = { ...setStateObj, getFolderStructureFlag: false }
                }
                setState(setStateObj);
            } else {
                if (!!data.fileFolderDetails && !stopFileFolderFlag) {
                    findFileFolder(data.fileFolderDetails, currentActiveFolderId);
                }
            }
        })
    }

    useEffect(() => {
        if (!!state.closeModalFlag) {
            if (!!state.modalId && state.modalId === 'createFolderModal') {
                closeModal('', state.modalId, 'fields', state.closeModalFlag)
            } else {
                closeModal('', state.modalId, 'updateFileFields', state.closeModalFlag)
            }
        }
    }, [state.closeModalFlag])

    useEffect(() => {
        if (!!state.currentActiveFolderId) {
            findFileFolder([...props.savedFileList], state.currentActiveFolderId);
        } else {
            setState({ ...state, savedFileList: [...props.savedFileList] });
        }
    }, [JSON.stringify(props.savedFileList)])

    //console.log(props.savedFileList)
    //This function is called for collapse-button

    const handleFileChange = (e) => {
        if (!!props.setFileManager && !!e.target.files && e.target.files.length > 0) {
            let typeFlag = 0;
            let sizeFlag = 0;
            for (let file of e.target.files) {
                let currentFileType = !!file.name ? file.name.split(".").pop().toLowerCase() : '';
                if (currentFileType === 'exe') {
                    typeFlag++;
                }

                let currentFileSize = !!file.size ? file.size : '';
                if (currentFileSize > 100000000) {
                    sizeFlag++;
                }
            }
            if (typeFlag === 0 && sizeFlag === 0) {
                let checkIfExists = 0;
                let files = { ...e.target.files };
                for (var file in files) {
                    var fileName = files[file]['name'];
                    if (!!fileName && fileName !== 'item') {
                        checkIfExists = checkIfFileNameExists(state.savedFileList, state.currentActiveFolderId, fileName);
                    }
                }
                if (checkIfExists !== 0) {
                    props.onShowNotify('Same file already exists', 'error');
                } else {
                    setState({ ...state, saveFileFolderFlagType: 'file', tempFiles: e.target.files });
                }
            } else {
                if (typeFlag > 0) {
                    props.onShowNotify('.exe file not supported', 'error');
                }
                else {
                    props.onShowNotify('File size must be less than 100MB', 'error');
                }
            }
        }
    }

    const changeViewType = (e, viewType) => {
        let setStateObj = { ...state, viewType }
        if (viewType === 3) {
            setStateObj = { ...setStateObj, viewFileFolderPath: [{ id: 0, title: '/' }], currentActiveFolderId: 0, currentActiveFolderName: '', moveToFolderFlag: true }
        }
        setState(setStateObj)
    }

    const viewInnerFolder = (e, folderId, folderName) => {
        let viewFileFolderPath = [...state.viewFileFolderPath];
        viewFileFolderPath.push({ id: folderId, title: folderName })
        setState({ ...state, viewFileFolderPath: viewFileFolderPath, currentActiveFolderId: folderId, currentActiveFolderName: folderName, moveToFolderFlag: true });
    }

    const moveToFolder = (e, folderId, folderName) => {
        if (!!state.currentActiveFolderId && state.currentActiveFolderId !== folderId) {
            let viewFileFolderPath = [...state.viewFileFolderPath];
            let fileFolderIndex = findIndex(viewFileFolderPath, ['id', folderId]);
            viewFileFolderPath = viewFileFolderPath.slice(0, (parseInt(fileFolderIndex, 10) + 1));
            setState({ ...state, viewFileFolderPath: viewFileFolderPath, currentActiveFolderId: folderId, currentActiveFolderName: folderName, moveToFolderFlag: true });
        }
    }

    useEffect(() => {
        if (!!state.moveToFolderFlag) {
            if (state.currentActiveFolderId === 0) {
                setState({ ...state, savedFileList: props.savedFileList, moveToFolderFlag: false });
            } else {
                findFileFolder(props.savedFileList, state.currentActiveFolderId);
            }
        }
    }, [state.moveToFolderFlag])

    const downloadFile = (e, fileId, type, title = '') => {
        if (type === 'file') {
            setState({ ...state, downloadFileFlag: true, downloadFileId: fileId });
        } else {
            setState({ ...state, downloadFolderFlag: true, downloadFolderId: fileId, downloadFolderTitle: title });
        }
    }

    useEffect(() => {
        if (!!state.downloadFileFlag) {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_DOWNLOAD_FILE + state.downloadFileId,
                commonConfig.METHOD_TYPE_GET);
            props.onGetFile(executedArgument);
        } else {
            if (!!props.getFileUrl && Object.keys(props.getFileUrl).length > 0) {
                commonFuncObj.download(props.getFileUrl.fileName, props.getFileUrl.contentType, props.getFileUrl.fileContent);
                props.onResetProps({ name: 'getFileUrl', data: {} });
            }
        }
    }, [state.downloadFileFlag])

    useEffect(() => {
        if (!!state.downloadFileFlag && !!props.getFileUrl) {
            setState({ ...state, downloadFileFlag: false, downloadFileId: 0 });
        } else if (!!state.downloadFolderFlag && !!props.getFileUrl) {
            setState({ ...state, downloadFolderFlag: false, downloadFolderId: 0, downloadFolderTitle: '' });
        }
    }, [JSON.stringify(props.getFileUrl)])

    useEffect(() => {
        if (!!state.downloadFolderFlag) {
            let fileArr = [{
                "id": state.downloadFolderId,
                "title": state.downloadFolderTitle,
                "isFolder": true
            }];
            let executedArgument = new ExecuteArgs(endPointConfig.COMPONENT_LMS,
                endPointConfig.COMPONENT_DOWNLOAD_ARCHIVE_FILE, commonConfig.METHOD_TYPE_POST, fileArr);
            props.onGetFile(executedArgument);
        } else {
            if (!!props.getFileUrl && Object.keys(props.getFileUrl).length > 0) {
                commonFuncObj.download(props.getFileUrl.fileName, props.getFileUrl.contentType, props.getFileUrl.fileContent);
                props.onResetProps({ name: 'getFileUrl', data: {} });
            }
        }
    }, [state.downloadFolderFlag])

    const handleDelete = (id = null, success = false, component = '') => {
        if (!!success) {
            props.onResetProps({ name: 'materialsFolderStructureList', data: false });
            setState({ ...state, getFolderStructureFlag: true, deleteId: id, deleteComponent: component })
        } else {
            setState({ ...state, deleteId: id, deleteComponent: component })
        }
    }

    const handleDeleteTempFileFolder = (id = null, success = false, index = null) => {
        let deleteComponent = endPointConfig.COMPONENT_LMS_DELETE_TEMP_FILE_FOLDER;
        if (!!success) {
            setState({ ...state, deleteId: id, deleteComponent: deleteComponent, clearTempFiles: true })
        } else {
            setState({ ...state, deleteId: id, deleteComponent: deleteComponent, tempDeleteIndex: index, tempDeleteId: id })
        }
    }

    useEffect(() => {
        if (!!state.clearTempFiles) {
            let savedFileList = [...state.savedFileList];
            let tempFiles = filter(savedFileList, function (o) { return (o.id === state.tempDeleteId || o.folderId === state.tempDeleteId); });
            !!tempFiles && tempFiles.length > 0 && tempFiles.forEach((data, key) => {
                let tempIndex = !!data.folderId ? findIndex(savedFileList, ['folderId', state.tempDeleteId]) : findIndex(savedFileList, ['id', state.tempDeleteId]);
                savedFileList.splice(tempIndex, 1);
            })

            props.setFileManager(savedFileList, state.currentActiveFolderId, state.tempDeleteIndex, state.tempDeleteId);
            setState({ ...state, savedFileList, tempDeleteIndex: null, clearTempFiles: false })
        }
    }, [state.clearTempFiles])

    const generateFileFolderTreeView = (fileList, type) => {
        return (!!fileList && fileList.length > 0) ?
            fileList.map((file, index) => {
                let currentFileType = !!file.title ? file.title.split(".").pop().toLowerCase() : '';
                let className;
                let divClassName;
                switch (currentFileType) {
                    case 'pdf':
                        className = 'icon icon-file-pdf f-s-20 text-white';
                        divClassName = 'bg-danger-800 p-15 mr-3';
                        break;
                    case 'xls':
                    case 'xlsx':
                        className = 'icon icon-file-excel f-s-20 text-white';
                        divClassName = 'bg-success-800 p-15 mr-3';
                        break;
                    case 'jpg':
                    case 'jpeg':
                    case 'bmp':
                    case 'gif':
                    case 'png':
                        className = 'icon icon-image2 f-s-20 text-white';
                        divClassName = 'bg-primary-800 p-15 mr-3';
                        break;
                    case 'doc':
                    case 'docx':
                        className = 'icon icon-file-word f-s-20 text-white';
                        divClassName = 'bg-primary-800 p-15 mr-3';
                        break;
                    case 'rar':
                    case 'zip':
                        className = 'icon icon-file-zip f-s-20 text-white';
                        divClassName = 'bg-warning-600 p-15 mr-3';
                        break;
                    default:
                        className = 'icon icon-folder4 f-s-20 text-white';
                        divClassName = 'bg-warning-600 p-15 mr-3';
                        break;
                }
                return !!file.folderId ?
                    <ul key={index} className={type === 'main' ? 'marking-period-tree' : ''}>
                        <li>
                            <MarkersLink to="#" className="toogle-btn"></MarkersLink>
                            <div className="panel-default m-b-0">
                                <div className="panel-header p-0 align-items-center d-flex">
                                    <div className={divClassName}>
                                        <i className={className} />
                                    </div>
                                    <div className="align-self-center">
                                        <h5 className="f-s-16 text-dark m-0">{file.title}</h5>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="panel-element">
                                            <div className="button dropdown element-col p-15">
                                                <MarkersLink to="#" className="" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="icon icon-more2"></i>
                                                </MarkersLink>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                    {props.materialId !== 0 ?
                                                        <React.Fragment>
                                                            <MarkersLink className="dropdown-item text-dark" to="#" onClick={(e) => editFolder(e, file.title, file.id)}>{i18n.t("edit")}</MarkersLink>
                                                            <MarkersLink className="dropdown-item text-dark" to="#" onClick={() => handleDelete(file.id, false, props.deleteFolderEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                            <MarkersLink className="dropdown-item text-dark" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'folder', file.title)}>{i18n.t("download")}</MarkersLink>
                                                        </React.Fragment>
                                                        : <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.folderId, false, index)}>{i18n.t("delete")}</MarkersLink>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!!file.fileFolderDetails && file.fileFolderDetails.length > 0 && generateFileFolderTreeView(file.fileFolderDetails, 'sub')}
                        </li>
                    </ul> :
                    type === 'main' ?
                        <div key={index} className="listView">
                            <div className="panel-default mb-3">
                                <div className="panel-header p-0 align-items-center d-flex">
                                    <div className={divClassName}>
                                        <i className={className} />
                                    </div>
                                    <div className="align-self-center">
                                        <h5 className="f-s-16 text-dark m-0">{file.title}</h5>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="panel-element">
                                            <div className="button dropdown element-col p-15">
                                                <MarkersLink to="#" className="text-muted" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="icon icon-more2"></i>
                                                </MarkersLink>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                    {props.materialId !== 0 && (file.isNew === undefined || !file.isNew) ?
                                                        <React.Fragment>
                                                            <MarkersLink className="dropdown-item text-dark" to="#" onClick={(e) => editFile(e, file.title, file.authorName, file.id, file.fileId, file.materialFileId, file.createdOn, file.lastModifiedOn, file.fileSize)}>{i18n.t("edit")}</MarkersLink>
                                                            <MarkersLink className="dropdown-item text-dark" to="#" onClick={() => handleDelete(props.fromPage === "assignment" ? file.assignmentFileId : file.materialFileId, false, props.deleteFileEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                            <MarkersLink className="dropdown-item text-dark" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'file')}>{i18n.t("download")}</MarkersLink>
                                                        </React.Fragment>
                                                        : <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.id, false, index)}>{i18n.t("delete")}</MarkersLink>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <ul key={index}>
                            <li>
                                <div className="panel-default m-b-0">
                                    <div className="panel-header p-0 align-items-center d-flex">
                                        <div className={divClassName}>
                                            <i className={className} />
                                        </div>
                                        <div className="align-self-center">
                                            <h5 className="f-s-16 text-dark m-0">{file.title}</h5>
                                        </div>
                                        <div className="ml-auto">
                                            <div className="panel-element">
                                                <div className="button dropdown element-col p-15">
                                                    <MarkersLink to="#" className="" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="icon icon-more2"></i>
                                                    </MarkersLink>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        {props.materialId !== 0 && (file.isNew === undefined || !file.isNew) ?
                                                            <React.Fragment>
                                                                <MarkersLink className="dropdown-item text-dark" to="#" onClick={(e) => editFile(e, file.title, file.authorName, file.id, file.materialFileId, file.createdOn, file.lastModifiedOn, file.fileSize)}>{i18n.t("edit")}</MarkersLink>
                                                                <MarkersLink className="dropdown-item text-dark" to="#" onClick={() => handleDelete(props.fromPage === "assignment" ? file.assignmentFileId : file.materialFileId, false, props.deleteFileEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                                <MarkersLink className="dropdown-item text-dark" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'file')}>{i18n.t("download")}</MarkersLink>
                                                            </React.Fragment>
                                                            :
                                                            <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.id, false, index)}>{i18n.t("delete")}</MarkersLink>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
            }) : null;
    }

    const fileList = !!state.savedFileList ? state.savedFileList : [];
    return <React.Fragment>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label className="col-form-label">{i18n.t("assignementFiles")}</label>
                    <div className="panel markersFilesUpload">
                        <div className="text-right">
                            <p className="m-0">{i18n.t("maxSizeForNewFiles")}: 100MB</p>
                        </div>
                        <div className="panel-default">
                            <div className="panel-header py-2">
                                <div className="d-flex justify-content-between">
                                    <div className="uploardGroup btn-group">
                                        <div className="uploadBtn">
                                            <button className="btn btn-secondary"><i className="icon-file-empty"></i></button>
                                            <input multiple type="file" name="materialsList" onChange={handleFileChange} />
                                        </div>
                                        <button className="btn btn-secondary" data-toggle="modal" onClick={() => createFolder()}><i className="icon-folder4"></i></button>
                                    </div>
                                    <div className="viewGroup btn-group">
                                        <button className={state.viewType == 1 ? 'btn btn-secondary active' : 'btn btn-secondary'} onClick={(e) => changeViewType(e, 1)}><i className="icon-grid"></i></button>
                                        <button className={state.viewType == 2 ? 'btn btn-secondary active' : 'btn btn-secondary'} onClick={(e) => changeViewType(e, 2)}><i className="icon-list"></i></button>
                                        <button className={state.viewType == 3 ? 'btn btn-secondary active' : 'btn btn-secondary'} onClick={(e) => changeViewType(e, 3)}><i className="icon-folder4"></i></button>
                                    </div>
                                </div>
                                {!!state.viewFileFolderPath && state.viewFileFolderPath.length > 0 &&
                                    <div className="mt-1">
                                        {state.viewFileFolderPath.map((data, key) => {
                                            return <MarkersLink key={key} to="#" className="mr-2" onClick={(e) => moveToFolder(e, data.id, data.title)}><i className="icon-folder4 text-dark"></i> {data.title}</MarkersLink>;
                                        })}

                                    </div>}
                            </div>
                            <hr className="m-0" />
                            <div className="panel-body">
                                {state.viewType === 1 &&
                                    <div className="thumbnailView">
                                        <div className="row">
                                            {(!!fileList && fileList.length > 0) &&
                                                fileList.map((file, index) => {
                                                    let currentFileType = !!file.title ? file.title.split(".").pop().toLowerCase() : '';
                                                    let className;
                                                    switch (currentFileType) {
                                                        case 'pdf':
                                                            className = 'icon-file-pdf text-danger-800';
                                                            break;
                                                        case 'xls':
                                                        case 'xlsx':
                                                            className = 'icon-file-excel text-success-800';
                                                            break;
                                                        case 'doc':
                                                        case 'docx':
                                                            className = 'icon-file-word text-primary-800';
                                                            break;
                                                        case 'jpg':
                                                        case 'jpeg':
                                                        case 'bmp':
                                                        case 'gif':
                                                        case 'png':
                                                            className = 'icon-image2 text-primary-800';
                                                            break;
                                                        case 'rar':
                                                        case 'zip':
                                                            className = 'icon icon-file-zip text-warning-600';
                                                            break;
                                                        default:
                                                            className = 'icon-folder4 text-warning-600';
                                                            break;
                                                    }
                                                    return <div key={index} className="col-md-3">
                                                        <div className="card mb-3 text-center">
                                                            <div className="card-body">
                                                                {!!file.folderId ?
                                                                    <MarkersLink to="#" onClick={(e) => viewInnerFolder(e, file.folderId, file.title)}><i className={className} style={{ fontSize: 35 }}></i></MarkersLink>
                                                                    :
                                                                    <i className={className} style={{ fontSize: 35 }}></i>
                                                                }
                                                                {/* {props.materialId !== 0 && (file.isNew === undefined || !file.isNew) && */}
                                                                <div className="itemActions">
                                                                    <div className="dropdown">
                                                                        <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <i className="icon icon-more2"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                            {!!file.folderId ?
                                                                                props.materialId !== 0 && (file.isNew === undefined || !file.isNew) ?
                                                                                    <React.Fragment>
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={(e) => editFolder(e, file.title, file.id)}>{i18n.t("edit")}</MarkersLink>
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={() => handleDelete(file.id, false, props.deleteFolderEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'folder', file.title)}>{i18n.t("download")}</MarkersLink>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.folderId, false, index)}>{i18n.t("delete")}</MarkersLink>
                                                                                :
                                                                                props.materialId !== 0 && (file.isNew === undefined || !file.isNew) ?
                                                                                    <React.Fragment>
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={(e) => editFile(e, file.title, file.authorName, file.fileId, file.id, file.materialFileId, file.createdOn, file.lastModifiedOn, file.fileSize)}>{i18n.t("edit")}</MarkersLink>
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={() => handleDelete(props.fromPage === "assignment" ? file.assignmentFileId : file.materialFileId, false, props.deleteFileEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'file')}>{i18n.t("download")}</MarkersLink>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.id, false, index)}>{i18n.t("delete")}</MarkersLink>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* } */}
                                                            </div>
                                                            <div className="card-footer p-2">
                                                                {!!file.folderId ?
                                                                    <MarkersLink to="#" onClick={(e) => viewInnerFolder(e, file.folderId, file.title)}><p className="m-0">{file.title}</p></MarkersLink>
                                                                    :
                                                                    <p className="m-0">{file.title}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>}
                                {state.viewType === 2 &&
                                    <div className="listView">
                                        <div className="row">
                                            {(!!fileList && fileList.length > 0) &&
                                                fileList.map((file, index) => {
                                                    let currentFileType = !!file.title ? file.title.split(".").pop().toLowerCase() : '';
                                                    let className;
                                                    let divClassName;
                                                    switch (currentFileType) {
                                                        case 'pdf':
                                                            className = 'icon-file-pdf';
                                                            divClassName = 'p-15 mr-3 bg-danger-800';
                                                            break;
                                                        case 'xls':
                                                        case 'xlsx':
                                                            className = 'icon-file-excel';
                                                            divClassName = 'p-15 mr-3 bg-success-800';
                                                            break;
                                                        case 'doc':
                                                        case 'docx':
                                                            className = 'icon-file-word';
                                                            divClassName = 'p-15 mr-3 bg-primary-800';
                                                            break;
                                                        case 'jpg':
                                                        case 'jpeg':
                                                        case 'bmp':
                                                        case 'gif':
                                                        case 'png':
                                                            className = 'icon-image2';
                                                            divClassName = 'p-15 mr-3 bg-primary-800';
                                                            break;
                                                        case 'zip':
                                                        case 'rar':
                                                            className = 'icon-file-zip';
                                                            divClassName = 'p-15 mr-3 bg-warning-600';
                                                            break;
                                                        default:
                                                            className = 'icon-folder4';
                                                            divClassName = 'p-15 mr-3 bg-warning-600';
                                                            break;
                                                    }
                                                    return <div key={index} className="col-md-6">
                                                        <div className="panel-default mb-3">
                                                            <div className="panel-header p-0 align-items-center d-flex">
                                                                {!!file.folderId ?
                                                                    <MarkersLink className="d-flex" to="#" onClick={(e) => viewInnerFolder(e, file.folderId, file.title)}>
                                                                        <div className={divClassName}>
                                                                            <i className={className} style={{ fontSize: 20 }}></i>
                                                                        </div>
                                                                        <div className="align-self-center">
                                                                            <h5 className="f-s-16 text-dark m-0">{file.title}</h5>
                                                                        </div>
                                                                    </MarkersLink>
                                                                    :
                                                                    <div className="d-flex">
                                                                        <div className={divClassName}>
                                                                            <i className={className} style={{ fontSize: 20 }}></i>
                                                                        </div>
                                                                        <div className="align-self-center">
                                                                            <h5 className="f-s-16 text-dark m-0">{file.title}</h5>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="ml-auto">
                                                                    <div className="panel-element">
                                                                        <div className="button dropdown element-col p-15">
                                                                            <MarkersLink to="#" className="text-muted" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="icon icon-more2"></i>
                                                                            </MarkersLink>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                                {!!file.folderId ?
                                                                                    props.materialId !== 0 && (file.isNew === undefined || !file.isNew) ?
                                                                                        <React.Fragment>
                                                                                            <MarkersLink className="dropdown-item" to="#" onClick={(e) => editFolder(e, file.title, file.id)}>{i18n.t("edit")}</MarkersLink>
                                                                                            <MarkersLink className="dropdown-item" to="#" onClick={() => handleDelete(file.id, false, props.deleteFolderEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                                                            <MarkersLink className="dropdown-item" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'folder', file.title)}>{i18n.t("download")}</MarkersLink>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.folderId, false, index)}>{i18n.t("delete")}</MarkersLink>
                                                                                    :
                                                                                    props.materialId !== 0 && (file.isNew === undefined || !file.isNew) ?
                                                                                        <React.Fragment>
                                                                                            <MarkersLink className="dropdown-item" to="#" onClick={(e) => editFile(e, file.title, file.authorName, file.id, file.materialFileId, file.createdOn, file.lastModifiedOn, file.fileSize)}>{i18n.t("edit")}</MarkersLink>
                                                                                            <MarkersLink className="dropdown-item" to="#" onClick={() => handleDelete(props.fromPage === "assignment" ? file.assignmentFileId : file.materialFileId, false, props.deleteFileEndpoint)}>{i18n.t("delete")}</MarkersLink>
                                                                                            <MarkersLink className="dropdown-item" to="#" onClick={(e) => downloadFile(e, file.fileId ? file.fileId : file.id, 'file')}>{i18n.t("download")}</MarkersLink>
                                                                                        </React.Fragment>
                                                                                        : <MarkersLink className="dropdown-item" to="#" onClick={() => handleDeleteTempFileFolder(file.id, false, index)}>{i18n.t("delete")}</MarkersLink>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>}
                                {state.viewType === 3 &&
                                    <div className="folderTreeView panel">

                                        {generateFileFolderTreeView(fileList, 'main')}

                                    </div>}

                                <div className="fileUpload">
                                    <div className="fileThumbnail">
                                        <button className="btn btn-light"><i className="icon-arrow-down10 f-s-20"></i></button>
                                        <input /*directory="" webkitdirectory=""*/ multiple type="file" name="materialsList" onChange={handleFileChange} />
                                        <p className="m-0">You can drag and drop files here to add them</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- create folder modal --> */}
        <form action="">
            <div className="modal fade" id="createFolderModal">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{!!state.fields.id ? i18n.t("updateFolder") : i18n.t("createFolder")}</h5>
                            <button type="button" className="close" onClick={(e) => closeModal(e, 'createFolderModal', 'fields')} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">{i18n.t('folderName')} <span>*</span></label>
                                        <input type="text" maxLength={200} className="form-control" name="title" placeholder={i18n.t('enterFolderName')} value={state.fields.title} onChange={(e) => handleChange(e, 'fields')} />
                                        <Validation data={{ field: i18n.t('folderName'), errorClass: 'title_error', value: state.fields.title, isError: state.isError, validationType: 'regExp', regExp: UNIT_DESCID }} />
                                        {!!state.isErrorFolderName && <div className="alert alert-danger m-t-5" role="alert">
                                            <i className="icon-warning f-s-13"></i> {i18n.t('folderName') + ' ' + i18n.t("alexits")}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <MarkersLink to="#" className="btn btn-secondary" onClick={(e) => closeModal(e, 'createFolderModal', 'fields')}>{i18n.t("cancel")}</MarkersLink>
                            <MarkersLink to="#" className="btn btn-success" onClick={(e) => handleSubmit(e, 'folder')}>{!!state.fields.id ? i18n.t("updateFolder") : i18n.t("createFolder")}</MarkersLink>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        {/* <!-- edit file modal --> */}
        <form action="">
            <div className="modal fade" id="editFileModal">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("editUploadedFiles")}</h5>
                            <button type="button" className="close" onClick={(e) => closeModal(e, 'editFileModal', 'updateFileFields')} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group m-b-10">
                                        <MarkersLink to="#" className="btn btn-success m-r-10" onClick={(e) => downloadFile(e, state.updateFileFields.fileId, 'file')}>{i18n.t("download")}</MarkersLink>
                                        {/* <MarkersLink to="#" className="btn btn-danger">{i18n.t("delete")}</MarkersLink> */}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">{i18n.t('fileName')}</label>
                                        <input type="text" disabled value={state.updateFileFields.title || ''} className="form-control" name="title" placeholder="Assignment No.1" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">{i18n.t('author')}</label>
                                        <input type="text" disabled className="form-control" value={state.updateFileFields.authorName || ''} name="authorName" placeholder="authorName" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <MarkersCommonSelect
                                        label={i18n.t("Path")}
                                        name="folderId"
                                        value={state.updateFileFields.folderId || ''}
                                        optArray={props.materialsFolderList}
                                        multi={false}
                                        optionLabelTitle="folderURL"
                                        handleChange={(e) => handleChange(e, 'updateFileFields')}
                                        isError={state.isError}
                                        isRequired={true} />
                                </div>
                            </div>
                        </div>
                        <div className="p-15 text-right">
                            <MarkersLink to="#" className="btn btn-success mr-3" onClick={(e) => handleFilePathUpdate(e)}>{i18n.t("update")}</MarkersLink>
                            <MarkersLink to="#" className="btn btn-secondary" onClick={(e) => closeModal(e, 'editFileModal', 'updateFileFields')}>{i18n.t("cancel")}</MarkersLink>
                        </div>
                        <div className="modal-footer pt-3 bg-light border-top">
                            <div className="d-flex align-items-center w-100">
                                <div>
                                    <i className="icon-file-word text-primary" style={{ fontSize: 40 }}></i>
                                </div>
                                <div className="m-l-15">
                                    {!!state.updateFileFields.lastModifiedOn &&
                                        <p className="mb-0">Last Modified <Moment format={'DD MMM YYYY h.mm a'}>{state.updateFileFields.lastModifiedOn}</Moment></p>}
                                    {!!state.updateFileFields.createdOn &&
                                        <p className="mb-0">Created <Moment format={'DD MMM YYYY h.mm a'}>{state.updateFileFields.createdOn}</Moment></p>}
                                    <p className="mb-0">{!!state.updateFileFields.fileSize ? `Size ${state.updateFileFields.fileSize}` : commonConfig.DASH_VALUE}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <MarkersDelete
            deleteId={state.deleteId}
            getParamFlag={state.tempDeleteIndex === null ? false : true}
            param={props.uniqueId}
            componentName={endPointConfig.COMPONENT_LMS}
            endPoint={state.deleteComponent}
            setDeleteSuccess={state.tempDeleteIndex === null ? handleDelete : handleDeleteTempFileFolder}
        />
    </React.Fragment>
}
const mapStateToProps = state => {
    return {
        saveFileFolderMaterials: state.commonStore.saveFileFolderMaterials,
        saveFolderMaterials: state.commonStore.saveFolderMaterials,
        saveFileMaterials: state.commonStore.saveFileMaterials,
        getFileUrl: state.commonStore.getFileUrl,
        saveFilePath: state.commonStore.saveFilePath,
        materialsFolderStructureList: state.commonStore.materialsFolderStructureList,
        materialsFolderList: state.commonStore.materialsFolderList
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onFileFolderSaveSuccess: (fileData) => dispatch(actions.callCommonSaveApi(fileData, 'file folder materials', true)),
        onUpdateFilePathSuccess: (executeArgs) => dispatch(actions.callCommonSaveApi(executeArgs, 'file path')),
        onResetProps: (executeArgs) => dispatch(actions.commonStoreStateReset(executeArgs)),
        onGetFolderStructureSuccess: (executeArgs) => dispatch(actions.callCommonListApi(executeArgs, 'materials folder structure')),
        onGetFile: (executeArgs) => dispatch(actions.callCommonGetByApi(executeArgs, 'file url')),
        onSaveFolderSuccess: (executeArgs) => dispatch(actions.callCommonSaveApi(executeArgs, 'folder materials')),
        onSaveFileSuccess: (fileData) => dispatch(actions.callCommonSaveApi(fileData, 'file materials', true)),
        onGetMaterialsFolderListByActivityId: (executeArgs) => dispatch(actions.callCommonListApi(executeArgs, 'materials folder')),
        onShowNotify: (message, type) => dispatch(actions.showNotify(message, type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersFileManager);
