import { commonConfig } from '../../../common/commonLmsIndex';
const UNIT_DESCID = /^(?!\s)[0-9a-zA-Z ]+$/;
const initialFields = {
    id: 0,
    title: "",
    description: "",
    isFolderOnly: false,
    parentFolderId: 0,
    uniqueId: "",
    actualFolderId: 0,
    actualParentFolderId: 0
}
const initialUpdateFileFields = {
    id: 0,
    fileId: 0,
    title: '',
    authorName: '',
    folderId: 0,
    materialId: 0,
    createdOn: '',
    lastModifiedOn: '',
    fileSize: ''
}
const requiredUpdateFields = [
    { fieldName: 'title', fieldType: 'regExp', regExp: UNIT_DESCID },
    { fieldName: 'folderId', fieldType: 'default' }
]
const requiredFields = [
    { fieldName: 'title', fieldType: 'default' },
    { fieldName: 'folderId', fieldType: 'default' }
]
const integerFields = ['folderId']
export {
    initialFields,
    requiredFields,
    integerFields,
    initialUpdateFileFields,
    UNIT_DESCID
}
