import React, { createRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    BaseComponent, i18n, commonConfig, actionsCommon, sessionStore, ExecuteArgs, endPointConfig
} from '../commonLoginindex';
import { MarkersCommonSelect, DataTable } from '../commonLmsIndex';
import { isEqual } from 'lodash';
const dataTableObjStudentPasswordList = createRef();
const tableColumnsStudentPasswordList = [
    { name: 'checkall', header: 'checkall', columnName: 'selectStudentId', type: 'checkbox', params: ['id'], separator: ',', inputClass: 'check' },
    { name: 'studentName', header: 'Student Name', type: 'default' },
    { name: 'studentCode', header: 'Code', type: 'default' },
    { name: 'guardian', header: 'Notifiable Contacts', type: 'default' }
];
class StudentPasswordGenerationComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterFields: {
                studentnameorcode: '',
                gradeIds: '',
                isRegenerated: false
            },
            generatePasswordData: [],
            getAllStudentFlag: false,
            isGenerateOrIsRegenerate: false,
            isGenerateRegenerateFlag: false
        }
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_SCHOOL,
            commonConfig.COMPONENT_SCHOOL_GRADELEVEL_GETALL + "schoolId=" + new sessionStore().returnSchoolID() + "&inActiveInclude=" + false,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetAllGradeLevelSuccess(executedArgument);
    }
    componentDidMount = () => {
        this.getPendingPasswordStudentList();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.pendingPasswordStudentList, this.props.pendingPasswordStudentList)) {
            this.setState({ getAllStudentFlag: true }, () => {
                dataTableObjStudentPasswordList.current.returnDestroyTable('markers-datatableStudentPasswordList');
            });
        }
        if (nextProps.pendingPasswordStudentList && nextProps.pendingPasswordStudentList.length != 0 && this.state.getAllStudentFlag) {
            this.setState({ generatePasswordData: nextProps.pendingPasswordStudentList, getAllStudentFlag: false }, () => {
                dataTableObjStudentPasswordList.current.returnDestroyTable('markers-datatableStudentPasswordList');
                dataTableObjStudentPasswordList.current.returnResetTable(null, false, 1, 'asc', [], 'markers-datatableStudentPasswordList');
                window.$("#StudentPasswordGeneration").modal('show');
            });
        }
        if (nextProps.pendingPasswordStudentList && nextProps.pendingPasswordStudentList.length === 0) {
            this.setState({ generatePasswordData: [] }, () => {
                window.$("#StudentPasswordGeneration").modal('show');
            });
        }

        if (!!nextProps.saveGenerateRegenerateStudentPassword && this.state.isGenerateRegenerateFlag) {
            this.setState({ isGenerateRegenerateFlag: false, }, () => {
                this.props.onResetProps({ name: 'saveGenerateRegenerateStudentPassword', data: false });
                this.getPendingPasswordStudentList();
            });
        }
    }

    closeModal = () => {
        dataTableObjStudentPasswordList.current.returnDestroyTable('markers-datatableStudentPasswordList');
        window.$("#StudentPasswordGeneration").modal('hide');
        this.props.changeStudentPasswordGenerationFlag(false);
    }

    getPendingPasswordStudentList = () => {
        dataTableObjStudentPasswordList.current.returnDestroyTable('markers-datatableStudentPasswordList');
        this.setState({ getAllStudentFlag: true, isGenerateOrIsRegenerate: this.state.filterFields.isRegenerated }, () => {
            this.props.onResetProps({ name: 'pendingPasswordStudentList', data: [] });
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                endPointConfig.COMPONENT_GET_PENDING_PASSWORD_STUDENT_LIST + new sessionStore().returnSchoolID() + '&studentnameorcode=' + this.state.filterFields.studentnameorcode + '&gradeIds=' + this.state.filterFields.gradeIds + '&isRegenerated=' + this.state.filterFields.isRegenerated,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetPendingPasswordStudentList(executedArgument);
        });
    }

    handleChange = (event) => {
        let filterFields = { ...this.state.filterFields };
        if (event.target.type === "checkbox") {
            filterFields[event.target.name] = event.target.checked;
        } else {
            filterFields[event.target.name] = event.target.value.replace(/^\s+/g, '');
        }
        this.setState({ filterFields });
    }

    handleGeneratePassword = () => {
        if (dataTableObjStudentPasswordList.current.getCheckedValue('markers-datatableStudentPasswordList').length > 0) {
            let studentIds = [];
            dataTableObjStudentPasswordList.current.getCheckedValue('markers-datatableStudentPasswordList').forEach((data, i) => {
                studentIds.push(parseInt(data, 10));
            })
            this.setState({ isGenerateRegenerateFlag: true }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                    endPointConfig.COMPONENT_GENERATE_REGENERATE_STUDENT_PASSWORD,
                    commonConfig.METHOD_TYPE_POST, { schoolID: new sessionStore().returnSchoolID(), studentIds: studentIds.toString(), isRegeneratePassword: this.state.isGenerateOrIsRegenerate });
                this.props.onGenerateRegenerateStudentPassword(executedArgument);
            })
        } else {
            this.props.onShowNotify(i18n.t("pleaseSelectStudentS"), 'error');
        }
    }
    render() {
        return (
            <React.Fragment>
                <form action="">
                    <div className="modal fade" id="StudentPasswordGeneration">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{i18n.t("generatePasswordStudents")}</h5>
                                    <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body pb-0">
                                    <div className="card border mb-3">
                                        <div className="card-body px-3 pt-2 pb-0">
                                            <div className="row align-items-center">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="control-label">{i18n.t('searchStudent')}</label>
                                                        <input type="text" className="form-control" name="studentnameorcode" placeholder={i18n.t('studentNameCode')} value={this.state.filterFields.studentnameorcode} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <MarkersCommonSelect
                                                        label={i18n.t("gradeLevel")}
                                                        name="gradeIds"
                                                        title="gradeIds"
                                                        divClassName="form-group"
                                                        labelClassName="control-label"
                                                        value={this.state.filterFields.gradeIds || ''}
                                                        optArray={this.props.schoolGradeLevelList}
                                                        handleChange={this.handleChange}
                                                        isError={0}
                                                        isRequired={false}
                                                        optionLabelValue='id'
                                                        multi={true}
                                                        optionLabelTitle='gradeTitle'
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-0">
                                                        <label className="checkbox-block checkbox-block mt-0 mb-0">
                                                            <input name="isRegenerated" type="checkbox" checked={this.state.filterFields.isRegenerated} onChange={this.handleChange} />
                                                            <span className="checkmark"></span> {i18n.t('regeneratePassword')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer-body px-3 py-2 text-right bg-light border-top">
                                            <Link to="#" className="btn btn-success" onClick={() => this.getPendingPasswordStudentList()}>{i18n.t("search")}</Link>
                                        </div>
                                    </div>
                                    <div className="card border border-top-0 mb-3">
                                        <div className="card-body p-0">
                                            <DataTable
                                                ref={dataTableObjStudentPasswordList}
                                                tableColumns={tableColumnsStudentPasswordList}
                                                tableRows={this.state.generatePasswordData}
                                                isExportable={false}
                                                tableClass="markers-datatableStudentPasswordList"
                                            />
                                        </div>
                                        <div className="footer-body px-3 py-2 text-right bg-light border-top">
                                            <Link to="#" className="btn btn-success" onClick={() => this.handleGeneratePassword()}>{!!this.state.isGenerateOrIsRegenerate ? i18n.t('regeneratePassword') : i18n.t("generatePassword")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        pendingPasswordStudentList: state.commonStore.pendingPasswordStudentList,
        schoolGradeLevelList: state.commonStore.schoolGradeLevelList,
        saveGenerateRegenerateStudentPassword: state.commonStore.saveGenerateRegenerateStudentPassword
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetPendingPasswordStudentList: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'pending password student')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onGetAllGradeLevelSuccess: (executeArgs) => dispatch(actionsCommon.callSchoolListApi(executeArgs, 'GradeLevel')),
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onGenerateRegenerateStudentPassword: (executeArgs) => dispatch(actionsCommon.callCommonSaveApi(executeArgs, 'generate regenerate student password')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentPasswordGenerationComponent)