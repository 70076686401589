export class endPointConfig {
    static COMPONENT_CONTROLPANEL = 'controlpanel';
    static COMPONENT_LMS = 'lms';
    static COMPONENT_SCHOOL = 'school';
    static COMPONENT_ASSESSMENT = "assessment"
    // rubric
    static COMPONENT_LMS_GET_ALL_RUBRIC = 'v1/rubric/getallrubric';
    static COMPONENT_LMS_ATTACH_RUBRIC = 'v1/assignmentrubric/saveassignmentrubrics';
    static COMPONENT_LMS_GET_ASSIGNMENT_RUBRIC_BY_ID = 'v1/assignmentrubric/getassignmentrubricbyids?id=';
    static COMPONENT_LMS_DELETE_RUBRIC_SCALE = 'v1/rubriccriterialevel/deleterubriccriterialevel';
    static COMPONENT_LMS_DELETE_RUBRIC_CRITERIA = 'v1/rubriccriteria/deleterubriccriteria';
    static COMPONENT_LMS_MODIFY_RUBRIC = 'v1/rubric/updaterubric';
    static COMPONENT_LMS_DELETE_RUBRIC = 'v1/assignmentrubric/deleteassignmentrubric';
    static COMPONENT_LMS_SAVE_FORMAT_RUBRIC = 'v1/assignmentrubric/saveassignmentrubricformat';
    /* generate student password */
    static COMPONENT_GENERATE_REGENERATE_STUDENT_PASSWORD = 'v1/studentinfo/taskforselectivestudentpasswordgeneration';
    static COMPONENT_GET_PENDING_PASSWORD_STUDENT_LIST = 'v1/studentinfo/getpendingpasswordstudentlist?schoolId=';
    /*gradebook section*/
    static COMPONENT_GET_MARKING_PERIOD_DETAILS = 'v1/studentmarkingperiod/getmarkingperioddetails?CourseSectionId=';
    static COMPONENT_GET_SECTION_GRADE_DETAILS = 'v1/studentgradebook/getassignmentgradebooksummarybystudentidcoursesectionid?StudentId=';
    static COMPONENT_GET_SECTION_PROGRES_DETAILS = 'v1/studentgradebook/getgradebookprogressdetailsbystudentidcoursesectionid?StudentId=';
    static COMPONENT_GET_BOTH_GRADEBOOK_AND_STUDENT_DETAILS = 'v1/studentgradebook/getstudentgradebook?StudentId=';
    static COMPONENT_GET_DOWNLOAD_GRADEBOOK_DETAILS = 'v1/studentgradebook/downloadstudentgradebook?StudentId=';
    static COMPONENT_SCHOOL_COMMENT_GETALL = 'v1/codecomments/getcodecommentsbyschoolid?schoolId=';
    /* sent email teacher login */
    static COMPONENT_LMS_GET_ALL_SENT_EMAIL = 'v1/sendemail/getsentemails?courseSectionId=';
    static COMPONENT_LMS_GET_SENT_EMAIL_BY_ID = 'v1/sendemail/getemailbyid?emailId=';
    static COMPONENT_LMS_GET_ALL_INDIVIDUAL_LIST = 'v1/communication/getallstudentstaffparentlist';
    static COMPONENT_LMS_SEND_EMAIL = 'v1/sendemail/sendemail';
    static COMPONENT_LMS_DOWNLOAD_EMAIL_ATTACHMENTS = 'v1/sendemail/downloadattachmentfile?fileId=';
    /* sent message teacher login */
    static COMPONENT_LMS_GET_ALL_RECEIVED_MESSAGE = 'v1/communication/getallreceivemessagebycoursesectionid?courseSectionId=';
    static COMPONENT_LMS_GET_ALL_SENT_MESSAGE = 'v1/communication/getallsentmessagebycoursesectionid?courseSectionId=';
    static COMPONENT_LMS_GET_MESSAGE_BY_ID = 'v1/communication/getcommunicationbyid?id=';
    static COMPONENT_LMS_SEND_MESSAGE = 'v1/communication/sendmessage';
    //Grade Book teacher
    static COMPONENT_GET_GRADEBOOK_OF_STUDENTS_BY_FILTER = 'v1/teachergradebook/getgradebookofstudentsbyfilter';
    static COMPONENT_GET_GRADE_MARKETING_PERIOD = 'v1/studentmarkingperiod/getgradecyclemarkingperiodbysession?SessionId=';
    static COMPONENT_SAVE_GRADEBOOK_DETAILS = 'v1/teachergradebook/saveteachergradebook';
    //section grades
    static COMPONENT_GET_SECTION_OF_STUDENTS_BY_FILTER = 'v1/sectionprogressgradebook/getsectiongradebookofstudentsbyfilter';
    static COMPONENT_SAVE_SECTION_OF_STUDENTS_BY_FILTER = 'v1/sectionprogressgradebook/savesectionprogressgradebookdetails';
    //progress Gradess  teacher  
    /*student section teacher login*/
    static COMPONENT_LMS_GET_ALL_STUDENT_TEACHER_LOGIN = 'v1/coursesection/getstudentdetaillistbycoursesectionid?courseSectionId=';
    static COMPONENT_LMS_GET_ASSIGNMENT_BY_ACTIVITY_ID = 'v1/assignment/getassignmentbyactivityid?activityId=';
    /* assignment study materials */
    static COMPONENT_LMS_GET_ALL_ASSIGNMENT_STUDY_MATERIALS = 'v1/assignmentstudymaterial/getallassignmentstudymaterial?assignmentId=';
    static COMPONENT_LMS_SAVE_ASSIGNMENT_STUDY_MATERIALS = 'v1/assignmentstudymaterial/saveassignmentstudymateriallist';
    /*file assignment */
    static COMPONENT_LMS_GET_PEER_REVIEW_HIDE = 'v1/peerreviewresponse/updatehidepeerreview';
    static COMPONENT_STAFF = 'v1/associatedstaff/getassociatedteachersbycoursesectionid?courseSectionId=';
    static COMPONENT_STUDENT_STRUCTURE = 'v1/coursesection/getactivestudentlistbycourssectionid?courseSectionId=';
    static COMPONENT_LMS_GET_BY_FOR_ASSIGNMENT_SUBMISSION_DETAILS = "v1/assignmentsubmission/getbyidforassignmentsubmissiondetails?";
    static COMPONENT_LMS_PEER_REVIEW_RESPONSE_GET_BY_ID = "v1/peerreviewresponse/peerreviewresponsegetbyid?id=";
    static COMPONENT_LMS_GET_PEER_REVIEW_RESPONSE_BY_PEER_ID = "v1/peerreviewresponse/getpeerreviewresponsesbypeerid?peerReviewPeerId=";
    static COMPONENT_LMS_SAVE_PEER_REVIEW_RESPONSE = "v1/peerreviewresponse/savepeerreviewresponse";
    static COMPONENT_LMS_GET_PEER_REVIEW_RESPONSE_DETAILS = "v1/peerreviewresponse/getpeerreviewresponsedetails?";
    static COMPONENT_LMS_GET_ASSIGNMENT_BY_CATEGORY_ID = "v1/assignment/getassignmentbycategoryid?categoryId="
    static COMPONENT_LMS_DELETE_ASSIGNMENT = "v1/assignment/deleteassignment";
    static COMPONENT_LMS_GET_ASSIGNMENT_SUBMISSION_BY_ACTIVITY_ID = 'v1/assignment/getassignmentdetailsbyactivityid?activityId=';
    static COMPONENT_LMS_GET_ALL_UNITS_BY_COURSE_SECTION_ID = "v1/units/getallunitbycoursesectionid?courseSectionId=";
    static COMPONENT_LMS_ASSIGNMENT_SUBMISSION_FILE_SAVE = 'v1/assignmentsubmission/saveassignmentfilesubmission';
    static COMPONENT_LMS_GET_ASSIGNMENT_SUBMISSION_FILE_BY_ASSIGNMENT_ID = 'v1/assignmentsubmission/getassignmentsubmissionbyid?assignmentId=';
    static COMPONENT_LMS_ASSIGNMENT_SUBMISSION_FILE_DELETE = 'v1/assignmentsubmissionfile/deleteassignmentsubmissionfile';
    static COMPONENT_LMS_ASSIGNMENT_SUBMISSION_DELETE = 'v1/assignmentsubmission/deleteassignmentsubmission';
    //Activity discussion
    static COMPONENT_LMS_ACTIVITY_DISCUSSION_SAVE = 'v1/activitydiscussioncomments/saveactivitydiscussioncomment';
    static COMPONENT_LMS_ACTIVITY_DISCUSSION_GET_ALL = 'v1/activitydiscussioncomments/getall?ActivityId=';
    static COMPONENT_LMS_ACTIVITY_DISCUSSION_DELETE = 'v1/activitydiscussioncomments/deleteactivitydiscussioncomments';
    /* online text assignment*/
    static COMPONENT_LMS_SAVE_ONLINE_TEXT_ASSIGNMENT = 'v1/assignmentsubmission/saveassignmentsubmission';
    static COMPONENT_LMS_UPDATE_ONLINE_TEXT_ASSIGNMENT = 'v1/assignmentsubmission/updateassignmentsubmission';
    static COMPONENT_LMS_DELETE_ONLINE_TEXT_ASSIGNMENT = 'v1/assignmentsubmission/deleteassignmentsubmission';
    /* Take Attendance */
    static COMPONENT_GET_STUDENT_DETAILS_ATTENDANCE_DATA = 'v1/studentattendance/getstudentdetailsandattendancedata?';
    // attendance category and sub-category
    static COMPONENT_GET_ATTENDANCE_CATEGORY_BY_SCHOOL_ID = 'v1/attendancecategory/getallactiveattendancecategorybyschoolid?schoolId=';
    static COMPONENT_GET_ATTENDANCE_SUB_CATEGORY_BY_CATEGORY_ID = 'v1/attendancesubcategory/getallactiveattendancesubcategory?AttendanceCategoryId=';
    /* MIssing attendacne*/
    static COMPONENT_STUDENT_SAVE_ATTENDANCE = 'v1/studentattendance/savestudentattendance';
    static COMPONENT_STUDENT_SECTION_ATTENDANCE_DELETE = 'v1/studentattendance/deletestudentattendance';
    static COMPONENT_LMS_GET_MISSING_ATTENDANCE_PERIODS_FOR_TEACHER = 'v1/teacherperiodattendance/getmissingattendanceperiodsforteacher';
    static COMPONENT_LMS_GET_STUDENT_MISSING_ATTENDANCE = 'v1/studentattendance/getstudentmissingattendancefilters';
    //get teacher course Info for missing attendance
    static COMPONENT_STUDENT_ATTENDANCE_LIST = 'v1/studentattendance/getstudentattendancelist';
    /* file folder upload */
    static COMPONENT_LMS_SAVE_FILE_FOLDER = 'v1/materialfoldertemporarystorage/savematerialtempfilefolder';
    static COMPONENT_LMS_UPDATE_FILE = 'v1/materialfile/updatematerialfile';
    static COMPONENT_LMS_GET_MATERIALS_FOLDER_LIST_BY_ACTIVITY_ID = 'v1/materialfolder/getmaterialfolderlistbyactivityid?activityId=';
    /* student quiz assignment */
    static COMPONENT_LMS_GET_ASSIGNMENT_ITEM = 'v1/studentquizassignment/getstudentquizassignmentdetails';
    static COMPONENT_GET_STUDENT_QUIZ_ASSIGNMENT_DETAILS_WITH_REMAININGTIME = "v1/studentquizassignment/GetStudentQuizAssignmentItemRemainingTime?assignmentItemId=";
    static COMPONENT_LMS_RESUME_ASSIGNMENT_ITEM = 'v1/studentquizassignment/resumesavestudentquizassignmentdetails';
    static COMPONENT_LMS_RESUME_INDIVIDUAL_ITEM = 'v1/studentquizassignment/saveassignmentresponseremainingtime';
    static COMPONENT_LMS_SAVE_ASSIGNMENT_ITEM = 'v1/studentquizassignment/savestudentquizassignmentdetails';
    static COMPONENT_LMS_GET_ASSIGNMENT_RESULT = 'v1/studentquizassignment/studentquizassignmentsubmitresponse?responseId=';
    static COMPONENT_LMS_GET_ASSIGNMENT_ITEM_FEEDBACK = 'v1/studentquizassignment/getstudentfeedbackquizresponsedetails';
    static COMPONENT_LMS_DELETE_ASSIGNMENT_ITEM_FILE = 'v1/studentquizassignment/deletestudentquizassignmentfile';
    /* discussion forum file folder upload */
    static COMPONENT_LMS_ASSIGNMENT_TEMP_FOLDER_SAVE = 'v1/assignmentfoldertemporarystorage/saveassignmenttempfilefolder';
    static COMPONENT_LMS_GET_ASSIGNMENT_FOLDER_LIST_BY_ASSIGNMENT_ID = 'v1/assignmentfolder/getassignmentfolderlistbyassignmentid?assignmentId=';
    static COMPONENT_LMS_GET_ASSIGNMENT_FOLDER_STRUCTURE_BY_ID = 'v1/discussionforum/getdiscussionforumfolderstructurebyid?discussionForumId=';
    static COMPONENT_LMS_FILE_UPDATE = 'v1/assignmentfile/updateassignmentfile';
    static COMPONENT_LMS_ASSIGNMENT_FILE_UPDATE = 'v1/assignmentfile/saveassignmentfile';
    static COMPONENT_LMS_ASSIGNMENT_FILE_DELETE = 'v1/assignmentfile/deleteassignmentfile';
    static COMPONENT_LMS_ASSIGNMENT_FOLDER_DELETE = 'v1/assignmentfolder/deleteassignmentfolder';
    static COMPONENT_LMS_ASSIGNMENT_FOLDER_UPDATE = 'v1/assignmentfolder/saveassignmentfolder';
    /* materials*/
    static COMPONENT_LMS_UPDATE_MATERIALS = 'v1/material/updatematerial';
    static COMPONENT_LMS_SAVE_MATERIALS = 'v1/material/savematerial';
    static COMPONENT_LMS_MATERIALS_GET_BY_ID = 'v1/material/getbyid?id=';
    static COMPONENT_LMS_MATERIALS_GET_BY_ACTIVITY_ID = 'v1/material/getmaterialbyactivityid?activityId=';
    static COMPONENT_LMS_GET_MATERIAL_FOLDER_STRUCTURE_BY_ID = 'v1/material/getmaterialfolderstructurebyid?materialId=';
    static COMPONENT_LMS_DELETE_MATERIALS = 'v1/material/deletematerial';
    static COMPONENT_LMS_DELETE_MATERIAL_FOLDER = 'v1/materialfolder/deletematerialfolder';
    static COMPONENT_LMS_DELETE_MATERIAL_FILE = 'v1/materialfile/deletematerialfile';
    static COMPONENT_LMS_SAVE_MATERIAL_FOLDER = 'v1/materialfolder/savematerialfolder';
    static COMPONENT_LMS_SAVE_MATERIAL_FILE = 'v1/materialfile/savematerialfile';
    static COMPONENT_LMS_MATERIALS_GET_ALL_BY_COURSE_ID = 'v1/coursesectionstudentstudymaterial/getstudymaterialbyids?courseId=';
    static COMPONENT_LMS_SAVE_COURSE_MATERIAL = 'v1/coursesectionstudentstudymaterial/saveuploadedmaterialfile';
    static COMPONENT_LMS_GET_ALL_COURSE_MATERIAL = 'v1/coursematerialdetails/getcoursematerialfileandfolderbycoursesectionid?courseSectionId=';
    static COMPONENT_LMS_GET_ALL_MATERIAL_BY_ID = 'v1/materialfile/getbyall?materialId=';
    static COMPONENT_LMS_SAVE_COURSE_MATERIAL_FILE = 'v1/studymaterialundercoursesection/savestudymaterialundercoursesection';
    static COMPONENT_LMS_UPDATE_MATERIAL_FOLDER = 'v1/materialfolder/updatematerialfolder';
    static COMPONENT_LMS_UPDATE_ASSIGNMENT_FOLDER = 'v1/assignmentfolder/updateassignmentfolder';
    /**Material Download**/
    static COMPONENT_DOWNLOAD_ARCHIVE_FILE = 'v1/coursematerialfile/downloadcoursematerialdetails';
    /* file manager section for assignment/quiz assignment */
    static COMPONENT_LMS_ASSIGNMENT_SAVE_FILE_FOLDER = 'v1/assignmentfoldertemporarystorage/saveassignmenttempfilefolder';
    static COMPONENT_LMS_ASSIGNMENT_UPDATE_FILE = 'v1/assignmentfile/updateassignmentfile';
    static COMPONENT_LMS_ASSIGNMENT_GET_FOLDER_STRUCTURE_BY_ID = 'v1/assignment/getassignmentfolderstructurebyid?assignmentId=';
    static COMPONENT_LMS_ASSIGNMENT_DELETE_FOLDER = 'v1/assignmentfolder/deleteassignmentfolder';
    static COMPONENT_LMS_ASSIGNMENT_DELETE_FILE = 'v1/assignmentfile/deleteassignmentfile';
    static COMPONENT_LMS_ASSIGNMENT_SAVE_FOLDER = 'v1/assignmentfolder/saveassignmentfolder';
    static COMPONENT_LMS_ASSIGNMENT_SAVE_FILE = 'v1/assignmentfile/saveassignmentfile';
    static COMPONENT_LMS_ASSIGNMENT_GET_FOLDER_LIST_BY_ASSIGNMENT_ID = 'v1/assignmentfolder/getassignmentfolderlistbyassignmentid?assignmentId=';
    /* Manage Vehicle */
    static COMPONENT_VEHICLE_SAVE = 'v1/transportvehicle/savevehicleinfo';
    static COMPONENT_VEHICLE_UPDATE = 'v1/transportvehicle/updatevehicleinfo';
    static COMPONENT_MANAGE_VEHICLES_GET_ALL = 'v1/transportvehicle/getvehicleinfopagination';
    static COMPONENT_MANAGE_VEHICLES_GET_BYID = 'v1/transportvehicle/getvehicleinfobyid?id=';
    static COMPONENT_VEHICLE_DELETE = 'v1/transportvehicle/deletevehicleinfo';
    static COMPONENT_DELETE_VEHICLE_FILE = 'v1/transportvehicle/deletevehicleimage';
    //validate subscription
    static VALIDATE_SUBSCRIPTION_BY_MODULE_KEY = 'v1/subscription/validatesubscription?moduleKey=';
    /* Manage Transport Staff */
    static COMPONENT_MANAGE_TRANSPORT_STAFF_GET_ALL = 'v1/transportstaff/getalltransportstaff'
    static COMPONENT_MANAGE_TRANSPORT_STAFF_GET_BYID = 'v1/transportstaff/gettransportstaffbyid?id='
    static COMPONENT_TRANSPORT_STAFF_SAVE = 'v1/transportstaff/savetransportstaff';
    static COMPONENT_TRANSPORT_STAFF_UPDATE = 'v1/transportstaff/updatetransportstaff';
    static COMPONENT_TRANSPORT_STAFF_DELETE = 'v1/transportstaff/deletetransportstaff';
    static COMPONENT_TRANSPORT_STAFF_EMPLOYMENTINFO_BYID = 'v1/transportstaff/getstaffemploymentinfo?staffId='
    static COMPONENT_TRANSPORT_GET_STAFF_CODE = 'v1/transportstaff/getstaffcode?schoolId='
    static COMPONENT_DELETE_PERSON_IMAGE = 'v1/person/deletephoto';
    /* Bus Stop */
    static COMPONENT_STOP_SAVE = 'v1/transportbusstop/savebusstop';
    static COMPONENT_STOP_UPDATE = 'v1/transportbusstop/updatebusstop';
    static COMPONENT_MANAGE_STOPS_GET_ALL = 'v1/transportbusstop/getallbusstopbyschoolpagination';
    static COMPONENT_MANAGE_STOP_GET_BYID = 'v1/transportbusstop/getbusstopbyid?id=';
    static COMPONENT_STOP_DELETE = 'v1/transportbusstop/deletebusstop';
    static COMPONENT_STOP_GET_BYSCHOOLID = 'v1/transportbusstop/getbusstopbyschoolid?schoolId=';
    /* Manage Routes */
    static COMPONENT_GET_ALL_ROUTE = 'v1/transportbusroute/getpaginationbusrouteinfo';
    static COMPONENT_ROUTE_SAVE = 'v1/transportbusroute/savebusroute';
    static COMPONENT_ROUTE_UPDATE = 'v1/transportbusroute/updatebusroute';
    static COMPONENT_GET_ROUTE_BYID = 'v1/transportbusroute/getbusroutebyid?id=';
    static COMPONENT_ROUTE_DELETE = 'v1/transportbusroute/deletebusroute';
    /* Manage Route Detail */
    static COMPONENT_ROUTE_DETAIL_SAVE = 'v1/transportroutedetail/saveroutedetail';
    static COMPONENT_ROUTE_DETAIL_UPDATE = 'v1/transportroutedetail/updateroutedetail';
    static COMPONENT_MANAGE_ROUTE_DETAIL_GET_ALL = 'v1/transportroutedetail/getallroutedetailpagination';
    static COMPONENT_MANAGE_ROUTE_DETAIL_GET_BYID = 'v1/transportroutedetail/getroutedetailbyid?id=';
    static COMPONENT_ROUTE_DETAIL_DELETE = 'v1/transportroutedetail/deleteroutedetail';
    /* Manage Staff Route Detail */
    static COMPONENT_STAFF_ROUTE_DETAIL_SAVE = 'v1/transportstaffroute/savestaffroute';
    static COMPONENT_STAFF_ROUTE_DETAIL_UPDATE = 'v1/transportstaffroute/updatestaffroute';
    static COMPONENT_STAFF_ADDED_ROUTE_DETAIL_GET_ALL = 'v1/transportstaffroute/gettransportstaffroutepagination';
    static COMPONENT_STAFF_ROUTE_DETAIL_GET_ALL = 'v1/transportstaffroute/getstaffroutelistbydepartment?schoolId=';
    static COMPONENT_STAFF_ROUTE_DETAIL_GET_BYID = 'v1/transportstaffroute/getstaffroutebyid?id=';
    static COMPONENT_STAFF_ROUTE_DETAIL_DELETE = 'v1/transportstaffroute/deletetstaffroute';
    static COMPONENT_STAFF_ROUTE_GET_AVAILABLE_SEAT = 'v1/transportstaffroute/getavailableseat?vehicleRoute=';
    static COMPONENT_GET_ALL_TRANSPORT_STOPPAGE_ROUTE = 'v1/transportstaffroute/getstoppagesbyvehiclerouteid?vehicleRoute=';
    /* Common person */
    static COMPONENT_SCHEDULE_TEACHER_GETALL = 'v1/staffinfo/getallteacherfromstaffbyschool?schoolId=';
    static COMPONENT_STAFF_GET_STAFF_HOLIDAY_LIST = 'v1/staffinfo/getallstaffforholidaylist?schoolId=';
    /* Transport Request */
    static COMPONENT_TRANSPORT_REQUEST_GET_ALL = 'v1/transportrequest/gettransportrequestpagination';
    static COMPONENT_TRANSPORT_GET_REQUEST_BY_ID = 'v1/transportrequest/gettransportrequestbyid?id=';
    static COMPONENT_TRANSPORT_REQUEST_GET_STUDENT_BY_FILTER = 'v1/transportrequest/getstudentbyfilter?schoolId=';
    static COMPONENT_TRANSPORT_SAVE_REQUEST = 'v1/transportrequest/savetransportrequest';
    static COMPONENT_TRANSPORT_APPROVE_DECLINE_REQUEST = 'v1/transportrequest/approvedeclinerequest';
    static COMPONENT_TRANSPORT_GET_REQUEST_BY_STUDENT_ID = 'v1/transportrequest/gettransportrequestbystudentid?studentId=';
    static COMPONENT_TRANSPORT_UPDATE_REQUEST = 'v1/transportrequest/updatetransportrequest';
    static COMPONENT_TRANSPORT_REQUEST_DELETE = 'v1/transportrequest/deletetransportrequest';
    /* Request Pickup Drop */
    static COMPONENT_GET_PICKUP_DROP_REQUEST_BY_STUDENT_ID = 'v1/guardianpickdroprequest/gettransportrequestbystudentid?studentId=';
    static COMPONENT_PICKUP_DROP_REQUEST_SAVE = 'v1/guardianpickdroprequest/savetransportrequest';
    static COMPONENT_PICKUP_DROP_REQUEST_UPDATE = 'v1/guardianpickdroprequest/updatetransportrequest';
    static COMPONENT_GET_PICKUP_DROP_REQUEST_BY_ID = 'v1/guardianpickdroprequest/gettransportrequestbyid?id=';
    static COMPONENT_GET_CURRENT_PICKUP_DROP_BY_STUDENT_ID = 'v1/guardianpickdroprequest/getcurrentpickupdropbystudentid?studentId=';
    static COMPONENT_PICKUP_DROP_REQUEST_DELETE = 'v1/guardianpickdroprequest/deletetransportrequest';
    /* Request Pickup Drop Approval */
    static COMPONENT_GET_APPROVE_DECLINE_REQUEST_BY_GRADE_ID = 'v1/adminapprovedeclinerequest/getapprovedeclinerequestbygradeid?';
    static COMPONENT_GET_APPROVE_DECLINE_REQUEST_BY_ID = 'v1/adminapprovedeclinerequest/getstudentapprovedeclinebyid?id=';
    static COMPONENT_UPDATE_APPROVE_DECLINE_REQUEST = 'v1/adminapprovedeclinerequest/updateapprovedeclinerequest';
    /* Manage Velicle Log */
    static COMPONENT_GET_DRIVER_CONDUCTOR_DETAILS = 'v1/transportvehiclelog/getprimarydriverconductordeatils?';
    static COMPONENT_GET_ALL_VEHICLE_LOG = 'v1/transportvehiclelog/getvehiclelogbyschoolid?';
    static COMPONENT_VEHICLE_LOG_SAVE = 'v1/transportvehiclelog/savevehicleloginfo';
    static COMPONENT_VEHICLE_LOG_UPDATE = 'v1/transportvehiclelog/updatevehicleloginfo';
    static COMPONENT_GET_VEHICLE_LOG_BYID = 'v1/transportvehiclelog/getvehicleloginfobyid?id=';
    static COMPONENT_GET_VEHICLE_LOG_BY_ROUTEID = 'v1/transportvehiclelog/getvehiclelogbyvechiclerouteid?';
    static COMPONENT_GET_VEHICLE_LOG_VIEW = 'v1/transportvehiclelog/viewvehicleloginfobyid?id=';
    static COMPONENT_GET_VEHICLE_LOG_DELETE = 'v1/transportvehiclelog/deletevehicleloginfo';
    static COMPONENT_INCIDENT_UPDATE = 'v1/transportvehiclelog/updatevehiclelogincidentinfo';
    static COMPONENT_INCIDENT_SAVE = 'v1/transportvehiclelog/savevehiclelogincidentinfo';
    static COMPONENT_VEHICLE_LOG_DELETE_IMAGE = 'v1/transportvehiclelog/deletephoto';
    //course shell coursesection
    static UPDATE_COURSE_SHELL_COURSE_SECTION = 'v1/courseshellcoursesection/updatecourseshellcoursesection';
    static COMPONENT_GET_COURSE_SHELL_COURSE_SECTION_BY_ID = 'v1/courseshellcoursesection/getcourseshellcoursesectiondetailsbyid';
    //course shell
    static GET_COURSE_SECTION_CURRENT_DATE_BY_TEACHER_ID = 'v1/courseshell/getcoursesectionsoncurrentdatebyteacherid';
    static GET_COURSE_SECTION_CURRENT_DATE_BY_STUDENT_ID = 'v1/courseshell/getcoursesectionsoncurrentdatebystudentid';
    static GET_COURSE_SECTION_CURRENT_DATE_BY_PARENT_ID = 'v1/courseshell/getcoursesectionsoncurrentdatebyparentid';
    static COURSE_SHELL_COURSE_SECTION_UPDATE_SORT_ORDER = 'v1/courseshellcoursesection/updatesortorder';
    static GET_COURSE_SHELL_BY_TEACHER_ID = 'v1/courseshell/getcourseshellbyteacherid';
    /* Manage Vehicle Route */
    static COMPONENT_GET_ALL_TRANSPORT_ROUTE = 'v1/transportvehicleroute/getallvehicleroute?sessionId=';
    static COMPONENT_GET_VEHICLE_ROUTE_BYID = 'v1/transportvehicleroute/getvehicleroutebyid?id=';
    static COMPONENT_VEHICLE_ROUTE_SAVE = 'v1/transportvehicleroute/savevehicleroutedetail';
    static COMPONENT_VEHICLE_ROUTE_UPDATE = 'v1/transportvehicleroute/updatevehicleroutedetail';
    static COMPONENT_VEHICLE_ROUTE_DELETE = 'v1/transportvehicleroute/deletevehicleroute?id='
    /* Manage Student Route */
    static COMPONENT_MANAGE_STUDENT_ROUTE_GET_ALL = 'v1/transportstudentroute/gettransportstudentroutepagination';
    static COMPONENT_GET_STUDENT_ROUTE_BYID = 'v1/transportstudentroute/getstudentroutebyid?id='
    static COMPONENT_STUDENT_ROUTE_DELETE = 'v1/transportstudentroute/deletetstudentroute'
    static COMPONENT_GET_STUDENT_BY_FILTER = 'v1/transportstudentroute/getstudentbyfilter'
    static COMPONENT_STUDENT_ROUTE_SAVE = 'v1/transportstudentroute/savestudentroute'
    static COMPONENT_STUDENT_ROUTE_UPDATE = 'v1/transportstudentroute/updatestudentroute'
    /* Take Attendance */
    static COMPONENT_GET_VEHICLE_LOG = 'v1/takeattendance/getvehicleloginfobyvechicleroute?';
    static COMPONENT_GET_ATTENDANCE_STUDENT_LIST = 'v1/takeattendance/getstudentlistattendance?';
    static COMPONENT_TAKE_ATTENDANCE = 'v1/takeattendance/takestudentattendance?';
    static COMPONENT_GET_STUDENT_COUNT = 'v1/takeattendance/getscheduledstudentcount?';
    /* Custom Notification */
    static COMPONENT_MANAGE_CUSTOM_NOTIFICATION_PAGINATION_GET_ALL = 'v1/customnotification/getcustomnotificationpagination';
    static COMPONENT_GET_CUSTOM_NOTIFICATION_BYID = 'v1/customnotification/getcustomnotificationbyid?id=';
    static COMPONENT_CUSTOM_NOTIFICATION_SAVE = 'v1/customnotification/savecustomnotification'
    static COMPONENT_CUSTOM_NOTIFICATION_UPDATE = 'v1/customnotification/updatecustomnotification'
    static COMPONENT_CUSTOM_NOTIFICATION_DELETE = 'v1/customnotification/deletecustomnotification?id='
    /*---Control panel---*/
    static COMPONENT_GET_SYSTEM_MAINTENANCE_BY_USER_ID = 'v1/systemmaintenance/getsystemmaintenancevaluebyuserid?UserId=';
    static COMPONENT_DISTRICT_GET_DISTRICT_SCHOOL_PREVIOUS_SESSION_ACESSS = 'v1/allowadmintoeditprevioussessiondata/getbydistrictid?';
    static COMPONENT_DISTRICT_GET_GENERAL_INFO = 'v1/districtgeneralinfo/getbydistrictid?Id=';
    /*---Unit----*/
    static COMPONENT_LMS_SAVE_UNIT = 'v1/units/saveunit';
    static COMPONENT_LMS_UPDATE_UNIT = 'v1/units/updateunit';
    static COMPONENT_LMS_UNIT_GET_ALL = 'v1/unitstructuredetails/getallunitlistbycoursesectionid';
    static COMPONENT_LMS_UNIT_DELETE = 'v1/units/deleteunit';
    static COMPONENT_LMS_UNIT_GET_BY_ID = 'v1/units/getunitbyid?id=';
    // Student endpoint needed to change here
    static COMPONENT_LMS_UNIT_GET_ALL_BY_STUDENT_ID = 'v1/unitstructuredetails/getcoursestructuredetaulsbystudentid';
    /* course structure label */
    static COMPONENT_LMS_SAVE_LABEL = 'v1/label/savelabel';
    static COMPONENT_LMS_UPDATE_LABEL = 'v1/label/updatelabel';
    static COMPONENT_LMS_LABEL_GET_BY_ID = 'v1/label/getbyid?id=';
    static GET_ALL_ACTIVITY = "v1/activity/getallactivity?";
    static COMPONENT_LMS_SAVE_PAGE = 'v1/page/savepage';
    static COMPONENT_LMS_DELETE_ACTIVITY_PREREQUISITE = 'v1/activityprerequisite/deleteactivityprerequisite';
    /* course structure label */
    static COMPONENT_LMS_SAVE_ASSIGNMENT = 'v1/assignment/saveassignment';
    static COMPONENT_LMS_UPDATE_PAGE = 'v1/page/updatepage';
    static COMPONENT_LMS_PAGE_GET_BY_ID = 'v1/page/getpagebyid?id=';
    static COMPONENT_LMS_PAGE_GET_BY_ACTIVITY_ID = 'v1/page/getbyactivityid?ActivityId=';
    static COMPONENT_LMS_DELETE_LABEL = 'v1/label/deletelabel';
    static COMPONENT_LMS_DELETE_PAGE = 'v1/page/deletepage';
    static COMPONENT_LMS_UPDATE_COURSE_STRUCTURE_INDENT = 'v1/activity/updateactivityindent';
    static COMPONENT_LMS_UPDATE_COURSE_STRUCTURE_ORDER = 'v1/activity/updateactivitysortorder';
    static COMPONENT_LMS_GET_ACTIVITY_BY_ID = 'v1/activity/getbyid';
    //Course Section Details
    static COMPONENT_LMS_GET_COURSE_SECTION_DETAILS = 'v1/coursesection/getcourssectiondetailsbyid?courseSectionId=';
    // assignment
    static COMPONENT_ASSESSMENT = 'lms';
    static COMPONENT_ATTENDANCE = 'attendance';
    static COMPONENT_ASSIGNMENT_BANK_GET_ALL = 'v1/assignmentbank/getallassignmentbankbyschoolid?schoolId=';
    static COMPONENT_ASSIGNMENT_BANK_GET_BYID = 'v1/assignmentbank/getassignmentbankbyid?id=';
    static COMPONENT_GET_SUBJECT_BY_TEACHER_ID = 'v1/attendanceviewfilter/getallsubjectsforteacherbygradeid?teacherId=';
    static COMPONENT_GET_COURSE_BY_SUBJECT_ID = 'v1/attendanceviewfilter/getallcoursesforteacherbysubjectid?teacherId=';
    static COMPONENT_GET_COURSE_SECTION_BY_COURSE_ID = 'v1/attendanceviewfilter/getallcoursesectionsforteacherbycourseid?teacherId=';
    static COMPONENT_ASSIGNMENT_CATEGORY_GET_ALL_BY_COURSESECTIONID = 'v1/assignmentcategory/getassignmentcategoriesbycoursesectionid?courseSectionId=';
    static LOOKUP_ASSIGNMENT_BANK_STATUS = 'ASSIGNMENT_BANK_PUBLICATION_STATUS';
    static COMPONENT_ASSIGNMENT_BANK_SAVE = 'v1/assignmentbank/saveassignmentbank';
    static COMPONENT_ASSIGNMENT_BANK_UPDATE = 'v1/assignmentbank/updateassignmentbank';
    static COMPONENT_ASSIGNMENT_BANK_DELETE = 'v1/assignmentbank/deleteassignmentbank';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_GET_ALL_BY_ASSESSMENT_BANKID = 'v1/assignmentitem/getallassignmentitemsbybankids?assignmentBankId=';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_GET_ALL_BY_BANKID = 'v1/assignmentitem/getallassignmentitemsbyassignmentgroupid?assignmentItemGroupId=';
    static COMPONENT_ASSIGNMENT_NEW_ITEM = 'v1/assignmentitem/addassignmentitem'
    static COMPONENT_ASSIGNMENT_GROUP_ITEM_GET_ALL_BY_BANKID = 'v1/assignmentitemgroup/getallassignmentitemgroup?AssignmentBankId=';
    static COMPONENT_ASSIGNMENT_ITEM_DELETE_BY_ID = 'v1/assignmentactivityitem/deleteassignmentactivityitem';
    static COMPONENT_ASSIGNMENT_BANK_GET_ALL_RUBRIC = 'v1/rubric/getallrubric?rubricId=';
    static COMPONENT_ASSIGNMENT_GET_RUBRICINFO_BY_ACTIVITYID = 'v1/assignmentgradebookrubric/getrubricinfobyactivityid';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_GET_BYID = 'v1/assignmentitem/getassignmentitembyid?id=';
    static LOOKUP_QUESTION_TYPE_SUBJECTIVE = 25603;
    static LOOKUP_QUESTION_TYPE_MCQ = 25602;
    static LOOKUP_QUESTION_TYPE_TRUE_FALSE = 25601;
    static LOOKUP_ASSIGNMENT_QUESTION_TYPE = 'ASSIGNMENT_QUESTION_TYPE';
    static LOOKUP_LMS_FILE_TYPE = 'LMS_FILE_TYPE';
    static LOOKUP_FILE_SIZE_TYPE = 'FILE_SIZE_TYPE';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_POSSIBLE_RESPONSE_DELETE = 'v1/assignmentitempossibleresponse/deleteassignmentitempossibleresponse';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_DELETE = 'v1/assignmentitem/deleteassignmentitem';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_SAVE = 'v1/assignmentitem/saveassignmentitem';
    static COMPONENT_ASSIGNMENT_BANK_ITEM_UPDATE = 'v1/assignmentitem/updateassignmentitem';
    static COMPONENT_ASSIGNMENT_BANK_CHILD_ITEM_GET_ALL = 'v1/assignmentitem/getallassignmentitemsbyparentids?parentItemId=';
    static COMPONENT_ASSIGNMENT_BANK_GET_ALL_BY_COURSESECTIONID = 'v1/assignmentbank/getallassignmentbankbycoursesectionid?courseSectionId='
    //ASSIGNMENT GROUP
    static COMPONENT_ASSIGNMENT = 'assessment';
    static COMPONENT_ASSIGNMENT_GROUP_SAVE = 'v1/assignmentitemgroup/saveassignmentitemgroup';
    static COMPONENT_ASSIGNMENT_GROUP_UPDATE = 'v1/assignmentitemgroup/updateassignmentitemgroup';
    static COMPONENT_GET_ALL_ASSIGNMENT_GROUP = 'v1/assignmentitemgroup/getallassignmentitemgroup?id=';
    static COMPONENT_ASSIGNMENT_GROUP_ITEM_DELETE = 'v1/assignmentitemgroup/deleteassignmentitemgroup';
    static COMPONENT_ASSIGNMENT_GROUP_ITEM_GET_BYID = 'v1/assignmentitemgroup/getassignmentitemgroupbyid?id=';
    // ASSIGNMENT
    static COMPONENT_ASSIGNMENT_CATEGORY_GET_ALL_BYTEACHERID = 'v1/assignmentcategory/getassignmentcategoriesbyteacher?teacherId=';
    static COMPONENT_COURSE_SECTIONS_BYTEACHERID = 'v1/assignmentcategory/getcoursesectionsbyteacherid?teacherId=';
    static COMPONENT_COURSE_SECTION_ACTIVE_STUDENTLIST = 'v1/coursesection/getactivestudentlistbycourssectionid?courseSectionId=';
    static COMPONENT_RESOURCE_GET_COLOUR = 'v1/colour/getallcolour';
    static COMPONENT_LMS_GET_ASSIGNMENT_CATEGORY_BY_TEACHER = "v1/assignmentcategory/getassignmentcategoriesbyteacher?teacherId=";
    static COMPONENT_SCHOOL_GETALL_GRADESCALE_CATEGORY = 'v1/schoolgradescale/getallschoolgradescale?schoolid=';
    static COMPONENT_ASSIGNMENT_CATEGORY_SAVE = 'v1/assignmentcategory/saveassignmentcategory';
    static COMPONENT_ASSIGNMENT_CATEGORY_UPDATE = 'v1/assignmentcategory/updateassignmentcategory';
    static COMPONENT_ASSIGNMENT_CATEGORY_GET_BYID = 'v1/assignmentcategory/getassignmentcategorybyid?id=';
    static COMPONENT_GET_ALL_ASSIGNMENT_BY_CATEGORY_ID = 'v1/assignment/getallbyassignmentcategoryid?assignmentCategoryId=';
    static COMPONENT_ASSIGNMENT_CATEGORY_GET_BYID = 'v1/assignmentcategory/getassignmentcategorybyid?id=';
    static COMPONENT_GET_ASSIGNMENT_STUDY_MATERIAL_BY_ID = 'v1/assignmentstudymaterial/getstudymaterialbyassignmentid?';
    static COMPONENT_ASSIGNMENT_CATEGORY_DELETE = 'v1/assignmentcategory/deleteassignmentcategory';
    static COMPONENT_SAVE_ASSIGNMENT = 'v1/assignment/saveassignment';
    static COMPONENT_UPDATE_ASSIGNMENT = 'v1/assignment/updateassignment';
    static COMPONENT_DELETE_ASSIGNMENT = 'v1/assignment/deleteassignment';
    static COMPONENT_GET_ALL_ASSIGNMENT = 'v1/assignment/getallassignment?unitId=';
    static COMPONENT_GET_ASSIGNMENT_BY_ID = 'v1/assignment/getassignmentbyid?id=';
    static COMPONENT_GET_ASSIGNMENT_BY_STUDENT_ID = 'v1/assignment/getassignmentdetailsbystudentid?studentId=';
    static COMPONENT_GET_RUNNING_AVERAGE_BY_STUDENT_ID = 'v1/studentassignment/getcalculaterunningaverage?coursesectionid=';
    //Discussion Forum
    static COMPONENT_DISCUSSION_FORUM_SAVE = 'v1/discussionforum/savediscussionforum';
    static COMPONENT_DISCUSSION_FORUM_UPDATE = 'v1/discussionforum/updatediscussionforum';
    static COMPONENT_DISCUSSION_FORUM_DELETE = 'v1/discussionforum/deletediscussionforum ';
    static COMPONENT_DISCUSSION_FORUM_GETALL = 'v1/discussionforum/getall';
    static COMPONENT_DISCUSSION_FORUM_GET_BYID = 'v1/discussionforum/getdiscussionforumbyid?id=';
    static COMPONENT_GET_BY_ACTIVITYID = 'v1/discussionforum/getdiscussionforumbyactivityid?activityId=';
    static COMPONENT_DISCUSSION_FORUM_STUDENT_NOTIFICATION_SAVE = 'v1/discussionforumstudentnotification/save';
    static COMPONENT_DISCUSSION_FORUM_GET_STUDENT_NOTIFICATION = 'v1/discussionforumstudentnotification/getbydiscussionforumidstudentpersonid?discussionId=';
    //Discussion Forum Submission
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_GET_BY_DISCUSSION_ID = 'v1/discussionforumsubmission/getdetailsbyforumid?discussionForumId=';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_GET_DETAILS = 'v1/discussionforum/getdiscussionforumdetails?studentId=';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_GET_ALL_DETAILS = 'v1/discussionforumsubmission/getdiscussionforumdetailsbyidstudentpid?discussionForumId=';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_READ = 'v1/discussionforumsubmission/forumsubmissionread';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_DELETE = 'v1/discussionforumsubmission/deletefile'
    // static COMPONENT_DISCUSSION_FORUM_SUBMISSION_SAVE = 'v1/teacherreviewresponse/saveteacherreviewresponse';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_SAVE = 'v1/teacherreviewresponse/savediscussionforumgrading';
    //Assessment
    static COMPONENT_ASSIGNMENT_CATEGORY_GET_ALL_BY_COURSESECTIONID = 'v1/assignmentcategory/getassignmentcategoriesbycoursesectionid?courseSectionId=';
    //School
    static COMPONENT_SCHOOL_GETALL_GRADESCALE_CATEGORY = 'v1/schoolgradescale/getallschoolgradescale?schoolid=';
    static GET_ALL_ACTIVITY_BY_COURSESECTION = "v1/activity/getallactivity?courseSectionId=";
    //Quiz Assignment
    static COMPONENT_QUIZ_ASSIGNMENT_SAVE = 'v1/quizassignment/savequizassignment';
    static COMPONENT_QUIZ_ASSIGNMENT_UPDATE = 'v1/quizassignment/updatequizassignment';
    static COMPONENT_QUIZ_ASSIGNMENT_GETALL = 'v1/quizassignment/getallquizassignment';
    static COMPONENT_QUIZ_ASSIGNMENT_GET_BYID = 'v1/quizassignment/getquizassignmentbyid?id=';
    //Discussion Forum Submission
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_GETALL = 'v1/discussionforumsubmission/getbyforumid?discussionForumId=';
    static COMPONENT_DISCUSSION_FORUM_SAVE_SUBMISSION = 'v1/discussionforumsubmission/savediscussionforumsubmission';
    static COMPONENT_DISCUSSION_FORUM_UPDATE_SUBMISSION = 'v1/discussionforumsubmission/updatediscussionforumsubmission';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_GET_BY_ID = 'v1/discussionforumsubmission/getdiscussionforumsubmissionbyid?discussionForumSubmissionId=';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_HIDE_POST = 'v1/discussionforumsubmission/updatehidedetails';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_DELETE_POST = 'v1/discussionforumsubmission/updatedeletepostdetails';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_TEACHER_COMMENT_GETALL = 'v1/discussionforumsubmission/getdiscussionforumssubmissiononlyteachercomment?forumId=';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_BYDATE = 'v1/discussionforumsubmission/getbyforumidandsubmissiondate?';
    static COMPONENT_DISCUSSION_FORUM_SUBMISSION_GETBY_FORUM_ONLY_STUDENT_GUARDIAN_LOGIN = 'v1/discussionforumsubmission/getbyforumidonlystudentguardianlogin?discussionForumId='
    //Quiz Assignment
    static COMPONENT_QUIZ_ASSIGNMENT_SAVE = 'v1/quizassignment/savequizassignment';
    static COMPONENT_QUIZ_ASSIGNMENT_UPDATE = 'v1/quizassignment/updatequizassignment';
    static COMPONENT_QUIZ_ASSIGNMENT_GETALL = 'v1/quizassignment/getallquizassignment';
    static COMPONENT_QUIZ_ASSIGNMENT_GET_BYID = 'v1/quizassignment/getquizassignmentbyid?id=';
    static COMPONENT_QUIZ_ASSIGNMENT_BY_ACTIVITYID = 'v1/quizassignment/getquizassignmentbyactivityid?activityId=';
    static COMPONENT_LMS_GET_QUIZASSIGNMENT_FOLDER_STRUCTURE_BY_ID = 'v1/quizassignment/getquizfolderstructurebyid?id=';
    static COMPONENT_LMS_GET_ALL_UNITS_BY_COURSE_SECTION_ID = "v1/units/getallunitbycoursesectionid?courseSectionId=";
    static COMPONENT_GET_ASSIGNMENT_SUBMISSION_DETAILS_BYID = "v1/assignmentsubmission/getassignmentsubmissiondetails?Id=";
    static COMPONENT_GET_ASSIGNMENT_SUBMISSION_DETAILS_BY_ASSIGNMENTID = "v1/assignmentsubmission/getassignmentsubmissiondetailsbyteacherid?AssignmentId=";
    static COMPONENT_GET_ASSIGNMENT_SUBMISSION_STUDENT_DETAILS_BY_ASSIGNMENTID = "v1/assignmentsubmission/getstudentdetailsbyassignmentid?AssignmentId=";
    static COMPONENT_GET_STUDENT_QUIZ_RESPONSE_DETAILS = "v1/studentquizassignment/getstudentquizresponsedetails?AssignmentId=";
    static COMPONENT_GET_STUDENT_QUIZ_ASSIGNMENT_DETAILS = "v1/studentquizassignment/getstudentquizassignmentdetails?studentId=";
    static COMPONENT_QUIZ_SUBMISSION_SAVE = 'v1/studentquizassignment/savestudentquizassignmentdetails';
    /**************************/
    static COMPONENT_GET_ALL_ASSIGNMENT_ITEM = 'v1/assignmentitem/getallassignmentitemsbybankid?assignmentBankId=';
    static COMPONENT_GET_ALL_ASSIGNMENT_BANK_BY_ASSIGNMENT_CATEGORY = 'v1/assignmentitem/getallassignmentitemsbycategoryid?categoryId=';
    static COMPONENT_GET_ALL_ASSIGNMENT_ITEM_BY_COURSE_ID = 'v1/assignmentitem/getallassignmentitemsbycourseid?courseId=';
    /**************************/
    /*************B*************/
    static COMPONENT_COURSE_MATERIALS_FOLDER_SAVE = 'v1/coursematerialfolder/savecoursematerialfolder';
    static COMPONENT_COURSE_MATERIALS_FOLDER_UPDATE = 'v1/coursematerialfolder/updatecoursematerialfolder';
    static COMPONENT_COURSE_MATERIALS_FILE_UPDATE = 'v1​/coursematerialfile​/updatecoursematerialfile';
    static COMPONENT_COURSE_MATERIALS_FILE_SAVE = 'v1/coursematerialfile/savecoursematerialfile';
    static COMPONENT_COURSE_MATERIALS_LIST = 'v1/coursematerialfolder/getallcoursematerialbycoursesectionid?courseSectionId=';
    static COMPONENT_COURSE_MATERIALS_DELETE = 'v1/coursematerialfile/deletecoursematerialfile'
    // static COMPONENT_COURSE_MATERIALS_FILE_FETCH_BY_COURSE_SECTION_ID_FOLDER_ID = 'v1/coursematerialfolder/getcoursematerialfolderbyid?courseSectionId='
    static COMPONENT_COURSE_MATERIALS_BULK_DELETE = 'v1/coursematerialfile/bulkcourmaterialdelete';
    static OMPONENT_COURSE_MATERIALS_BULK_INACTIVE = 'v1/coursematerialfile/bulkcourmaterialinactive';
    static OMPONENT_COURSE_MATERIALS_FOLDER_FETCH_BY_COURSE_SECTION_ID_FOLDER_ID = 'v1/coursematerialfolder/getcoursematerialfolderbyid?courseSectionId=';
    static COMPONENT_COURSE_MATERIALS_FILE_FETCH_BY_COURSE_SECTION_ID_FOLDER_ID = 'v1/coursematerialfolder/getcoursematerialfilebyid?courseSectionId=';
    /*************B*************/
    /***** Announcement *****/
    static COMPONENT_ANNOUNCEMENT_SAVE = 'v1/announcement/saveannouncement';
    static COMPONENT_ANNOUNCEMENT_UPDATE = 'v1/announcement/updateannouncement';
    static COMPONENT_ANNOUNCEMENT_GETALL_BY_SCHOOL = 'v1/announcement/getannouncementbyschoolrolecoursesection';
    static COMPONENT_ANNOUNCEMENT_GET_BYID = 'v1/announcement/getannouncementbyid?id=';
    static COMPONENT_ANNOUNCEMENT_GETALL = 'v1/announcement/getannouncementbyuserrolecoursesectionid';
    static COMPONENT_ANNOUNCEMENT_REMOVE_FILE = 'v1/announcement/removeannouncementfile';
    static COMPONENT_ANNOUNCEMENT_DELETE = 'v1/announcement/deleteannouncement';
    /***** School Role *****/
    static COMPONENT_SCHOOLROLE_GETALL_FOR_TEACHER = 'v1/schoolrole/getallroleforteacherlogin?schoolId=';
    /***** Get UserProfile picture *****/
    static COMPONENT_GET_PROFILE_PICTURE_BY_USER_ID = 'v1/resource/getprofilepicbyuserid?id=';
    // Upload Maximum File Size
    static COMPONENT_UPLOAD_FILE_SIZE_GET_BYID = 'v1/uploadfilesize/getbydistrictid?DistrictId=';
    // notifications
    static COMPONENT_GET_RECEIVE_MESSAGES_LIST = 'v1/communication/getallreceivemessagesbyroleid?roleid=';
    static COMPONENT_LMS_DELETE_TEMP_FILE_FOLDER = 'v1/materialfoldertemporarystorage/delete?Id=';
    // student assignment
    static COMPONENT_GET_ALL_REPORT_CARD_CYCLE_GRADE_MPS = 'v1/markingperiod/getreportcardcyclegradempslist?schoolSessionId=';
    static COMPONENT_GET_MESSAGE_SENT_LIST = 'v1/adminsendemail/getsentemails';
    static COMPONENT_STUDENT_GET_ROLE_BASED_MENU_PERMISSIONS = 'v1/appmenu/getrolebasedappmenu?moduleKey=';


    //Copy Structure
    static COMPONENT_LMS_GET_ACTIVE_COURSE_SECTION_BY_TEACHER_ID = 'v1/courseshell/getactivecoursesectionsbyteacherid';
    static COMPONENT_LMS_COPY_COURSE_STRUCTURE = 'v1/unitstructuredetails/copycoursestructure';
    static COMPONENT_COPY_REJECTED_COURSE_COMPONENTS = 'v1/unitstructuredetails/copyrejectedcoursecomponents';

    //teacher gradebook
    static COMPONENT_STUDENTS_BY_COURSE_SECTION = 'v1/teachergradebook/getstudentsbyassignments?assignmentIds=';
    static COMPONENT_ASSIGNMENT_BY_COURSE_SECTION = 'v1/teachergradebook/getassignmentsbycoursesection?courseSectionId=';
    static COMPONENT_SCHOOL_MARKING_PERIOD_GET_GRADEBOOK_PROGRESS_MP = 'v1/studentmarkingperiod/checkprogresscyclemarkingperiodbyparent?ParentId=';
    static COMPONENT_SCHOOL_GET_TEACHER_ATTENDANCE_BY_SCHOOL_ID = 'v1/schoolcontrolitems/getteachereditattendancebyschooid?SchoolId=';
    // gradebook get running average
    static COMPONENT_ASSESMENT_GET_RUNNING_AVERAGE_CALCULATION = 'v1/teachergradebook/getassignmentscalculation';
    // copy assignment
    static COMPONENT_ASSESMENT_GET_COURSE_SECTION_GRADES_BY_ID = 'v1/assignmentcategory/getcoursesectionandunitdetailsbyteacherassignmentid?teacherId='
    static COMPONENT_ASSESMENT_COPY_SUBMIT = 'v1/assignment/copyassignmentdetails'
    static COMPONENT_ASSIGNMENT_UPDATE_ELIGIBLE_DROP_STATUS = "v1/assignment/updateassignmenteligiblefordropstatus?id=";
    //gradebook post grades or progress
    static COMPONENT_LMS_SAVE_GRADEBOOK_POST_GRADE_OR_PROGRESS = 'v1/teachergradebook/postgradeorprogress';
}
export default endPointConfig;
