export const VALIDATE_LOGIN_SUCCESS = 'VALIDATE_LOGIN_SUCCESS ';
export const HANDLE_LOGIN_FAIL = 'HANDLE_LOGIN_FAIL';
export const HANDLE_LOGIN_PENDING = 'HANDLE_LOGIN_PENDING';
export const GET_LOGIN_URL_SUCCESS = 'GET_LOGIN_URL_SUCCESS ';
export const HANDLE_LOGIN_URL_FAIL = 'HANDLE_LOGIN_URL_FAIL ';
export const GET_RESOURCES_SUCESS = 'GET_RESOURCES_SUCESS';
export const GET_LAST_SESSION_SUCESS = 'GET_LAST_SESSION_SUCESS';
export const SET_LAST_SESSION_SUCESS = 'SET_LAST_SESSION_SUCESS';
export const GET_ALL_MENUS_SUCCESS = 'GET_ALL_MENUS_SUCCESS';
export const GET_USER_MENUS_SUCCESS = 'GET_USER_MENUS_SUCCESS';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const UPDATE_MARKASREAD_NOTIFICATION_SUCCESS = 'UPDATE_MARKASREAD_NOTIFICATION_SUCCESS';
export const UPDATE_USER_PREFERENCE_SUCCESS = 'UPDATE_USER_PREFERENCE_SUCCESS';
export const GET_USER_PREFERENCE_SUCCESS = 'GET_USER_PREFERENCE_SUCCESS';
export const HANDLE_GET_ALL_MENUS_FAIL = 'HANDLE_GET_ALL_MENUS_FAIL';
export const GET_FORGOT_PASSWORD_OTP_SUCCESS = 'GET_FORGOT_PASSWORD_OTP_SUCCESS';
export const CONFIRM_FORGOT_PASSWORD_OTP_SUCCESS = 'CONFIRM_FORGOT_PASSWORD_OTP_SUCCESS';
export const SAVE_CONFIRM_PASSWORD_SUCCESS = 'SAVE_CONFIRM_PASSWORD_SUCCESS';
export const GENERATE_STUDENT_SCHEDULER_PASSWORD_SUCCESS = 'GENERATE_STUDENT_SCHEDULER_PASSWORD_SUCCESS';
export const GET_SYSTEM_MAINTENANCE_USER_ID_SUCCESS = 'GET_SYSTEM_MAINTENANCE_USER_ID_SUCCESS';
