import React from "react";
import Validation from '@markerspro/validation-component';
const MarkersCheckbox = React.memo((props) => {
    const { divClassName, onlyField, title, required, isError } = props;
    let attribute = { ...props.attribute }
    if (!attribute.hasOwnProperty('checked')) {
        attribute = { ...attribute, checked: attribute.value }
        delete attribute.value
    }
    if (attribute.hasOwnProperty('placeholder'))
        delete attribute.placeholder
    const fieldResult = <React.Fragment>
        <label class="checkbox-block">
            <input type="checkbox" {...attribute} />
            <span class="checkmark"></span> {title}{!!required && <span> *</span>}
        </label>
        {!!required && <Validation data={{
            field: title,
            errorClass: attribute.name + '_error',
            value: attribute.value || '',
            isError: isError,
            validationType: 'checkbox'
        }} returnValidation={this.functionValidation} />}
    </React.Fragment>
    return (
        !!onlyField ?
            fieldResult :
            <div className={!!divClassName ? divClassName : "form-group"}>
                {fieldResult}
            </div>
    )
})
export default MarkersCheckbox;