import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as SharedStore from './sharedAction';
import LoginStore from '../../common/store/reducers/reducerLogin';
import CommonStore from '../../shared/store/reducer/reducerShared';


export default function sharedConfigureStore(history, initialState) {
    const reducers = {
        sharedStore: SharedStore.reducer,
        loginStore: LoginStore,
        commonStore: CommonStore
    };
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];
    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }
    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });
    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}